import React, { useEffect, useState } from "react";
import {
  ArrowLeft,
  CheckCircle,
  Clock,
  AlertCircle,
  XCircle,
  SkipForward,
} from "lucide-react";

import { useNavigate, useParams } from "react-router-dom";
import { useApiService } from "./hooks/apiService";
import useUserStore from "./hooks/user-store";
import { Popover, Modal } from "flowbite-react";
import Timeline from "./Timeline";
import { formatDate } from "./utils/date";
import SecretValue from "./components/SecretValue";
import ExternalLinkIcon from "./icons/ExternalLinkIcon";
import InlineCopy from "./components/InlineCopy";
import { toast } from "react-toastify";

const websiteCredentialFields = [
  { label: "WordPress Admin User", key: "wp_admin_user" },
  { label: "WordPress Admin Password", key: "wp_admin_pass" },
  { label: "WordPress API/App Password", key: "app_pass" },
  { label: "Database Name", key: "db_name" },
  { label: "Database User", key: "db_user" },
  { label: "Database Password", key: "db_pass" },
];

const getStatusIcon = (status) => {
  switch (status) {
    case "done":
      return <CheckCircle className="w-5 h-5 text-green-500" />;
    case "in_progress":
      return <Clock className="w-5 h-5 text-blue-500" />;
    case "failed":
      return <XCircle className="w-5 h-5 text-red-500" />;
    case "skipped":
      return <SkipForward className="w-5 h-5 text-gray-500" />;
    default:
      return <Clock className="w-5 h-5 text-gray-400" />;
  }
};

const getStatusBadge = (status) => {
  const baseClasses = "px-2 py-1 text-xs font-medium rounded-full";

  switch (status) {
    case "done":
      return (
        <span className={`${baseClasses} bg-green-100 text-green-800`}>
          Voltooid
        </span>
      );
    case "in_progress":
      return (
        <span className={`${baseClasses} bg-blue-100 text-blue-800`}>
          In uitvoering
        </span>
      );
    case "failed":
      return (
        <span className={`${baseClasses} bg-red-100 text-red-800`}>
          Mislukt
        </span>
      );
    case "skipped":
      return (
        <span className={`${baseClasses} bg-gray-100 text-gray-800`}>
          Overgeslagen
        </span>
      );
    case "todo":
      return (
        <span className={`${baseClasses} bg-gray-100 text-gray-600`}>
          Te doen
        </span>
      );
    case "paid":
      return (
        <span className={`${baseClasses} bg-green-100 text-green-800`}>
          Betaald
        </span>
      );
    default:
      return (
        <span className={`${baseClasses} bg-gray-100 text-gray-800`}>
          {status}
        </span>
      );
  }
};

const Order = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { apiService } = useApiService();
  const chatbotUUID = useUserStore((state) => state?.userData?.chatbot?.uuid);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updatingTaskId, setUpdatingTaskId] = useState(null);
  const [isDeliverModalOpen, setIsDeliverModalOpen] = useState(false);
  const allTasksDoneOrSkipped = order?.tasks?.every((task) =>
    ["done", "skipped"].includes(task.status),
  );

  const handleDeliverWebsite = async () => {
    try {
      const update = await apiService.deliverOrder(chatbotUUID, orderId);
      const updatedOrder = update.order;
      // Optionally update the order state or show a success message
      setOrder((prev) => ({
        ...prev,
        status: updatedOrder.status,
      }));
      console.log("Website delivered successfully");
      toast.success("Website succesvol opgeleverd");
    } catch (err) {
      console.error("Failed to deliver website:", err);
      // Show error notification here
    } finally {
      setIsDeliverModalOpen(false);
    }
  };

  useEffect(() => {
    async function fetchOrder() {
      try {
        setLoading(true);
        let result = await apiService.getOrderById(chatbotUUID, orderId);
        setOrder(result.order);
      } catch (err) {
        setError("Failed to load order data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
    if (chatbotUUID && orderId) {
      fetchOrder();
    }
  }, [chatbotUUID, orderId]);

  const updateTaskStatus = async (taskId, newStatus) => {
    try {
      setUpdatingTaskId(taskId);

      await apiService.setTaskStatus(chatbotUUID, orderId, taskId, newStatus);

      console.log(`Updating task ${taskId} to status: ${newStatus}`);

      // Update the local state
      setOrder((prevOrder) => {
        const updatedTasks = prevOrder.tasks.map((task) => {
          if (task.id === taskId) {
            const now = new Date().toISOString();
            return {
              ...task,
              status: newStatus,
              started_at:
                newStatus === "in_progress" && !task.started_at
                  ? now
                  : task.started_at,
              ended_at:
                newStatus === "done" ||
                newStatus === "failed" ||
                newStatus === "skipped"
                  ? now
                  : task.ended_at,
              updated_at: now,
            };
          }
          return task;
        });

        return {
          ...prevOrder,
          tasks: updatedTasks,
        };
      });
    } catch (err) {
      console.error("Failed to update task status:", err);
      // Show error notification here
    } finally {
      setUpdatingTaskId(null);
    }
  };

  const getStatusOptions = () => {
    const statuses = {
      todo: "Te doen",
      in_progress: "In uitvoering",
      done: "Voltooid",
      failed: "Mislukt",
      skipped: "Overgeslagen",
    };

    return Object.entries(statuses).map(([value, label]) => ({ value, label }));
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4" />
          <h2 className="text-xl font-semibold text-center text-red-700">
            Error
          </h2>
          <p className="text-gray-600 text-center">{error}</p>
          <button
            className="mt-4 w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
            onClick={() => window.location.reload()}
          >
            Probeer opnieuw
          </button>
        </div>
      </div>
    );
  }

  if (!order) {
    return null;
  }

  return (
    <div className="mx-auto">
      <div className="mb-6 flex items-center">
        <button
          className="flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          onClick={() => {
            // In a real implementation, this would be:
            navigate(-1);
            console.log("Navigate back");
          }}
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Terug
        </button>
      </div>

      {/* Order Header */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <div className="flex justify-between items-start">
          <div>
            <h1 className="text-2xl font-bold text-gray-800">
              Order #{order.id}
            </h1>
            <p className="text-gray-500">
              Aangemaakt op {formatDate(order.created_at)}
            </p>
            <div className="text-gray-500">
              Klant:{" "}
              <InlineCopy value={order.payment.chat_user.email}>
                <span>{order.payment.chat_user.email}</span>
              </InlineCopy>
            </div>
          </div>
          <div>{getStatusBadge(order.status)}</div>
        </div>
      </div>

      {/* Order Details */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-lg font-semibold mb-4 text-gray-800">
            Website Details
          </h2>
          <div className="space-y-3">
            <div className="flex justify-between">
              <span className="text-gray-600">Domein:</span>
              <span className="font-medium">
                <a
                  href={`https://${order.site_config.target_domain}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  {order.site_config.target_domain}
                </a>
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Site URL:</span>
              <a
                href={order.site_config.site_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {order.site_config.site_url}
              </a>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Site Status:</span>
              <span>{getStatusBadge(order.site_config.status)}</span>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-lg font-semibold mb-4 text-gray-800">Betaling</h2>
          <div className="space-y-3">
            <div className="flex justify-between">
              <span className="text-gray-600">Bedrag:</span>
              <span className="font-medium">
                €{order.payment.amount.toFixed(2)}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Status:</span>
              <span>{getStatusBadge(order.payment.status)}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Mollie Payment ID:</span>
              <span className="font-mono text-sm">
                <Popover
                  trigger="hover"
                  content={
                    <div className="p-6">
                      Open in Mollie Dashboard. Als dit een testbetaling was,
                      klik in het Mollie betaling scherm dan even op Test modus
                      om je testbetalingen te zien.
                    </div>
                  }
                >
                  <a
                    href={order.mollie_dashboard_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    {order.payment.mollie_payment_id}
                  </a>
                </Popover>
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Datum:</span>
              <span>{formatDate(order.payment.created_at)}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Tasks Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h2 className="text-lg font-semibold mb-4 text-gray-800">Taken</h2>
          <div className="space-y-4">
            {order.tasks.map((task) => (
              <div
                key={task.id}
                className="border-b border-gray-100 pb-4 last:border-0 last:pb-0"
              >
                <div className="flex items-start">
                  <div className="mr-3 mt-1">{getStatusIcon(task.status)}</div>
                  <div className="flex-1">
                    <div className="flex justify-between items-center mb-1">
                      <h3 className="font-medium text-gray-800">{task.name}</h3>
                      <div className="flex items-center">
                        {updatingTaskId === task.id ? (
                          <div className="animate-pulse mr-2">
                            <Clock className="w-4 h-4 text-blue-500" />
                          </div>
                        ) : null}
                        {order.status !== "done" && (
                          <select
                            value={task.status}
                            onChange={(e) =>
                              updateTaskStatus(task.id, e.target.value)
                            }
                            className="mr-2 text-sm rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                            disabled={updatingTaskId === task.id}
                          >
                            {getStatusOptions().map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {getStatusBadge(task.status)}
                      </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 text-sm text-gray-500">
                      {task.started_at && (
                        <div className="flex justify-between">
                          <span>Gestart:</span>
                          <span>{formatDate(task.started_at)}</span>
                        </div>
                      )}
                      {task.ended_at && (
                        <div className="flex justify-between">
                          <span>Voltooid:</span>
                          <span>{formatDate(task.ended_at)}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* Deliver Website Button */}
          <div className="mt-6 bg-white rounded-lg p-6">
            <div className="flex items-center justify-between">
              {order.status !== "done" ? (
                <>
                  <button
                    className={`px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 ${
                      !allTasksDoneOrSkipped
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    onClick={() => setIsDeliverModalOpen(true)}
                    disabled={!allTasksDoneOrSkipped}
                  >
                    Website Opleveren
                  </button>
                  {!allTasksDoneOrSkipped && (
                    <span className="text-sm text-gray-600 ml-4">
                      Deze functionaliteit is beschikbaar zodra alle taken zijn
                      voltooid.
                    </span>
                  )}
                </>
              ) : (
                <span className="text-sm text-gray-600">
                  Deze website is al opgeleverd. U kunt geen taken meer
                  wijzigen.
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h2 className="text-lg font-semibold mb-4 text-gray-800">
            Website inloggegevens
          </h2>
          <div className="flex justify-between">
            <span className="text-gray-600">WordPress Admin URL</span>
            <span className="font-mono text-sm">
              <a
                href={`${order.site_config?.site_url}/wp-admin`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {order.site_config.site_url}/wp-admin
                <ExternalLinkIcon />
              </a>
            </span>
          </div>
          {websiteCredentialFields.map((field) => (
            <div key={field.key} className="flex justify-between">
              <span className="text-gray-600">{field.label}:</span>
              <span className="font-mono text-sm">
                <SecretValue secret={order.site_config[field.key]} />
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* Timeline */}
      <Timeline tasks={order.tasks} />

      {/* Deliver Website Modal */}
      <Modal
        show={isDeliverModalOpen}
        onClose={() => setIsDeliverModalOpen(false)}
      >
        <Modal.Header>Website Opleveren</Modal.Header>
        <Modal.Body>
          <div className="space-y-4">
            <p>
              Door te bevestigen wordt de website gemarkeerd als opgeleverd en
              ontvangt de gebruiker een bevestigingsmail dat zijn site is
              gemigreerd en klaar voor gebruik.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end gap-4">
            <button
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              onClick={() => setIsDeliverModalOpen(false)}
            >
              Annuleren
            </button>
            <button
              className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              onClick={handleDeliverWebsite}
            >
              Bevestigen
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Order;
export { getStatusIcon, getStatusBadge };
