/**
 * Create a URL with query parameters
 * @param {string} baseUrl
 * @param {object} params
 * @returns {string}
 * @example
 * createUrl("https://example.com", { foo: "bar" });
 * // returns "https://example.com?foo=bar"
 * @example
 * createUrl("https://example.com", { foo: "bar", baz: "qux" });
 * // returns "https://example.com?foo=bar&baz=qux"
 */
function createUrl(baseUrl, params) {
  const url = new URL(baseUrl);
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key]),
  );
  return url.toString();
}

export default createUrl;
