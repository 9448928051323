import { Link } from "react-router-dom";
import Newsletter from "./Newsletter";
import { COMPANY_NAME } from "./constants";
import ArrowRight from "./icons/ArrowRight";

function Home() {
  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            {COMPANY_NAME}
          </h1>
          <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">
            {/* Bespaar tijd door uw klanten een AI generated website aan te bieden */}
            {/* gedreven door een ChatGPT chat bot */}
            Optimaliseer uw tijd en verbeter klanttevredenheid met een
            AI-gegenereerde website, aangedreven door een ChatGPT-chatbot.
          </p>
          <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
            <Link
              to="/trial"
              className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
            >
              {/* Nu uitproberen */}
              Ontdek de Toekomst Nu
              <ArrowRight />
            </Link>
            <Link
              to="/features"
              className="inline-flex justify-center items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            >
              {/* Lees meer */}
              Start UW AI-Revolutie
            </Link>
          </div>
        </div>
      </section>
      <Newsletter></Newsletter>
    </>
  );
}

export default Home;
