import React from "react";
import MainMenu from "./MainMenu";
import Footer from "./Footer";
import MenuProvider from "./hooks/menu-visibility";
import { Auth0ProviderWithNavigate } from "./Auth0ProviderWithNavigate";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import FlowbiteWrapper from "./components/flowbite-wrapper";
import { SidebarProvider } from "./SidebarContext";

function App() {
  return (
    <Auth0ProviderWithNavigate>
      <MenuProvider>
        <SidebarProvider>
          <MainMenu></MainMenu>
          <FlowbiteWrapper />
          <Footer></Footer>
        </SidebarProvider>
        <ToastContainer />
      </MenuProvider>
    </Auth0ProviderWithNavigate>
  );
}

export default App;
