import { Modal } from "flowbite-react";
import React from "react";
import OfferingForm from "./OfferingForm";

const OfferingAddModal = ({ chatbotUuid, show, setShowCallback }) => {
  return (
    <Modal show={show} popup dismissible onClose={() => setShowCallback(false)}>
      <Modal.Header className="mb-3 text-2xl font-bold text-gray-900 dark:text-white">
        Nieuwe klant aanbieding aanmaken 🎉
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4 text-sm font-light text-gray-500 dark:text-gray-400">
          <p>
            Vul de gegevens in en klik op de knop "Opslaan" om de aanbieding toe
            te voegen.
          </p>
          <OfferingForm
            chatbotUuid={chatbotUuid}
            successCallback={() => setShowCallback(false)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OfferingAddModal;
