import React, { useEffect } from "react";
import { Button, Navbar } from "flowbite-react";
import MyNavlink from "./MyNavlink";
import { useAuth0 } from "@auth0/auth0-react";
import { useMenuVisibility } from "./hooks/menu-visibility";
import useUserStore from "./hooks/user-store";
import CompanyLogo from "./components/CompanyLogo";

function MainMenu() {
  const { logout, isAuthenticated, user } = useAuth0();
  const updateUserState = useUserStore((state) => state.updateUserState);

  // why do I have this here, such bullshit Dino
  useEffect(() => {
    if (isAuthenticated && user) {
      updateUserState({
        fullname: user.name,
        email: user.email,
      });
    }
  }, [isAuthenticated, user, updateUserState]);

  console.log("user", user);
  const { isMenuVisible } = useMenuVisibility();
  if (!isMenuVisible) {
    return null;
  }
  return (
    <Navbar fluid rounded>
      <Navbar.Brand href="/">
        <CompanyLogo />
      </Navbar.Brand>
      <div className="flex md:order-2">
        <Button
          className="bg-primary-700"
          onClick={() => {
            window.sitechatproOpen();
          }}
        >
          Nu Uitproberen!
        </Button>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <MyNavlink to="/">Home</MyNavlink>
        {/* <MyNavlink to="/over">Over</MyNavlink> */}
        <MyNavlink to="/features">Features</MyNavlink>
        <MyNavlink to="/prijzen">Prijzen</MyNavlink>
        {/* <MyNavlink to="/contact">Contact</MyNavlink> */}

        {/* {isAuthenticated && <MyNavlink to="/profile">Profiel</MyNavlink>} */}
        {isAuthenticated && <MyNavlink to="/dashboard">Dashboard</MyNavlink>}
        {!isAuthenticated && <MyNavlink to="/login">Inloggen</MyNavlink>}
        {isAuthenticated && (
          <Navbar.Link
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            Uitloggen
          </Navbar.Link>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MainMenu;
