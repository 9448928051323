import React, { useEffect, useState } from "react";
import SettingsIcon from "./icons/SettingsIcon";
import PieChartIcon from "./icons/PieChartIcon";
import ShoppingBagIcon from "./icons/ShoppingBagIcon";
import DocumentIcon from "./icons/DocumentIcon";
import InboxIcon from "./icons/InboxIcon";
import ClipboardIcon from "./icons/ClipboardIcon";
import LifeBoeiIcon from "./icons/LifeBoeiIcon";
import SalePercentIcon from "./icons/SalePercentIcon";
import { Sidebar } from "flowbite-react";
import { Link, useLocation } from "react-router-dom";
import { twJoin, twMerge } from "tailwind-merge";
import { useSidebarContext } from "./SidebarContext";
import isSmallScreen from "./lib/is-small-screen";
import { Euro } from "lucide-react";
const asideMenu = [
  [
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: PieChartIcon,
    },
    {
      name: "Orders",
      path: "/dashboard/orders",
      icon: Euro,
    },
    {
      name: "Leads",
      path: "/dashboard/leads",
      icon: SalePercentIcon,
    },
    {
      name: "Chats",
      path: "/dashboard/chats",
      icon: InboxIcon,
    },
  ],
  [
    {
      name: "Chatbot Instellingen",
      path: "/dashboard/settings",
      icon: SettingsIcon,
    },
    {
      name: "Klant Aanbiedingen",
      path: "/dashboard/offerings",
      icon: DocumentIcon,
    },
    // {
    //   name: "Facturatie",
    //   path: "/dashboard/billing",
    //   icon: ShoppingBagIcon,
    // },
    // {
    //   name: "Abonnementen",
    //   path: "/dashboard/plan",
    //   icon: ShoppingBagIcon,
    // },
  ],
  [
    {
      name: "Tech. Documentatie",
      path: "/dashboard/documentation",
      icon: ClipboardIcon,
      hasLine: true,
    },
    {
      name: "Veelgestelde vragen",
      path: "/dashboard/faq",
      icon: LifeBoeiIcon,
    },
  ],
];

const DashboardSidebar = () => {
  const { isOpenOnSmallScreens: isSidebarOpenOnSmallScreens } =
    useSidebarContext();
  const { pathname: currentPage } = useLocation();

  return (
    <div
      className={twJoin("lg:!block", !isSidebarOpenOnSmallScreens && "hidden")}
    >
      <Sidebar
        // className={twMerge(
        //   "fixed inset-y-0 left-0 z-20 flex h-full shrink-0 flex-col border-r border-gray-200 pt-16 duration-75 lg:flex dark:border-gray-700",
        // )}
        collapsed={isSidebarOpenOnSmallScreens && !isSmallScreen()}
      >
        <div className="flex h-full flex-col justify-between">
          <Sidebar.Items>
            {asideMenu.map((group, sidebarIndex) => (
              <Sidebar.ItemGroup key={sidebarIndex}>
                {group.map((item, index) => (
                  <Sidebar.Item
                    key={index}
                    as={Link}
                    to={item.path}
                    icon={item.icon}
                    className={
                      item.path === currentPage
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    {item.name}
                  </Sidebar.Item>
                ))}
              </Sidebar.ItemGroup>
            ))}
          </Sidebar.Items>
        </div>
      </Sidebar>
    </div>
  );
};

export default DashboardSidebar;
