import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { COMPANY_NAME } from "./constants";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import CheckboxIconCircle from "./icons/CheckboxIconCircle";
import useTrialStore from "./hooks/trial-store";
import TrialMenu from "./TrialMenu";
import { useApiService } from "./hooks/apiService";
import CompanyLogo from "./components/CompanyLogo";

const Trial = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  console.log(user);
  const updateTrialRegistrationData = useTrialStore(
    (state) => state.updateTrialRegistrationData,
  );
  const trialRegistrationData = useTrialStore(
    (state) => state.trialRegistrationData,
  );
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    console.log("onsubmit, update state: " + JSON.stringify(data, null, 2));
    updateTrialRegistrationData(data);
    navigate("/trial/setup-chat");
  };
  const { apiService } = useApiService();

  if (errors) {
    console.log(`errors: ${JSON.stringify(errors, null, 4)}`);
  }

  useEffect(() => {
    const redirectIfHasTrial = async () => {
      let data = await apiService.hasTrial();
      console.log(data);
      if (data && data.has_trial) {
        navigate("/dashboard");
      }
    };
    redirectIfHasTrial();
  }, []);

  useEffect(() => {
    console.log(
      `update values from state: ${JSON.stringify(trialRegistrationData)}`,
    );
    if (trialRegistrationData.fullname !== null) {
      setValue("fullname", trialRegistrationData.fullname);
    }
    if (trialRegistrationData.companyName !== null) {
      setValue("companyName", trialRegistrationData.companyName);
    }
    if (trialRegistrationData.country !== null) {
      setValue("country", trialRegistrationData.country);
    }
    if (trialRegistrationData.agreeToTerms !== null) {
      setValue("agreeToTerms", trialRegistrationData.agreeToTerms);
    }
    if (trialRegistrationData.subscribeToNewsletter !== null) {
      setValue(
        "subscribeToNewsletter",
        trialRegistrationData.subscribeToNewsletter,
      );
    }
  }, []);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: "/trial",
      },
      authorizationParams: {
        screen_hint: "signup",
      },
    });
    return null;
  }

  return (
    <>
      <section className="py-8 bg-white dark:bg-gray-900 lg:py-0">
        <div className="lg:flex">
          <TrialMenu></TrialMenu>
          <div className="flex items-center mx-auto md:w-[42rem] px-4 md:px-8 xl:px-0">
            <div className="w-full">
              <div className="flex items-center justify-center mb-8 space-x-4 lg:hidden">
                <Link
                  to="/"
                  className="flex items-center text-2xl font-semibold"
                >
                  <CompanyLogo className="w-8 h-8 mr-2" />
                  <span className="text-gray-900 dark:text-white">
                    {COMPANY_NAME}
                  </span>
                </Link>
              </div>
              <ol className="flex items-center mb-6 text-sm font-medium text-center text-gray-500 dark:text-gray-400 lg:mb-12 sm:text-base">
                <li className="flex items-center text-primary-600 dark:text-primary-500 sm:after:content-[''] after:w-12 after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
                    <svg
                      className="w-4 h-4 mr-2 sm:mb-2 sm:w-6 sm:h-6 sm:mx-auto"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Account{" "}
                    <span className="hidden sm:inline-flex">Details</span>
                  </div>
                </li>
                <li className="flex items-center after:content-[''] after:w-12 after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
                    <CheckboxIconCircle></CheckboxIconCircle>
                    Configureer{" "}
                    <span className="hidden sm:inline-flex">Chat</span>
                  </div>
                </li>
                <li className="flex items-center sm:block">
                  <div className="mr-2 sm:mb-2 sm:mx-auto">3</div>
                  Bevestigen
                </li>
              </ol>
              <h1 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-900 sm:mb-6 leding-tight dark:text-white">
                Account details
              </h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid gap-5 my-6 sm:grid-cols-1">
                  <div>
                    <label
                      htmlFor="full-name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Volledige Naam
                    </label>
                    <input
                      type="text"
                      name="full-name"
                      id="full-name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Daan de Jong"
                      required={true}
                      defaultValue={user.name}
                      {...register("fullname")}
                    ></input>
                    {/* errors obj contains details info on what the actual error is, todo replace required error message with actual error message */}
                    {errors.fullname && <span>Dit veld is verplicht</span>}
                  </div>
                  <div>
                    <label
                      htmlFor="company-name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Bedrijfsnaam
                    </label>
                    <input
                      type="text"
                      name="company-name"
                      id="company-name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Uw bedrijfsnaam"
                      required={false}
                      {...register("companyName", { required: false })}
                    ></input>
                  </div>
                  <div>
                    <label
                      htmlFor="country"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Land
                    </label>
                    <select
                      id="country"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("country")}
                      defaultValue={"NL"}
                    >
                      <option value="NL">Nederland</option>
                      <option value="BE">België</option>
                      <option value="UK">United Kingdom</option>
                    </select>
                  </div>
                  {/* <div> */}
                  {/*   <label */}
                  {/*     htmlFor="phone-number" */}
                  {/*     className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" */}
                  {/*   > */}
                  {/*     Phone */}
                  {/*   </label> */}
                  {/*   <input */}
                  {/*     type="number" */}
                  {/*     name="phone-number" */}
                  {/*     id="phone-number" */}
                  {/*     className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" */}
                  {/*     placeholder="+123 567 890" */}
                  {/* required={true} */}
                  {/*   ></input> */}
                  {/* </div> */}
                </div>
                <div className="mb-6 space-y-3">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        name="newsletter"
                        id="newsletter"
                        aria-describedby="newsletter"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                        {...register("subscribeToNewsletter", {
                          required: false,
                        })}
                      ></input>
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="newsletter"
                        className="font-light text-gray-500 dark:text-gray-300"
                      >
                        Stuur mij e-mails over product updates en aanbiedingen
                      </label>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        name="terms"
                        id="terms"
                        aria-describedby="terms"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                        {...register("agreeToTerms")}
                      ></input>
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="terms"
                        className="font-light text-gray-500 dark:text-gray-300"
                      >
                        Bij het aanmelden maakt u een SiteChatPro-account aan en
                        gaat u akkoord met de{" "}
                        <Link
                          to="/gebruiksvoorwaarden"
                          className="font-medium text-primary-600 dark:text-primary-500 hover:underline"
                        >
                          Gebruiksvoorwaarden
                        </Link>{" "}
                        en het{" "}
                        <Link
                          to="/privacy-beleid"
                          className="font-medium text-primary-600 dark:text-primary-500 hover:underline"
                        >
                          Privacybeleid
                        </Link>{" "}
                        van SiteChatPro.
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex space-x-3">
                  {/* <a */}
                  {/*   href="#" */}
                  {/*   className="text-center items-center w-full py-2.5 sm:py-3.5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" */}
                  {/* > */}
                  {/*   Prev: Personal Info */}
                  {/* </a> */}
                  <button
                    type="submit"
                    className="w-full text-white bg-primary-700 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 sm:py-3.5 text-center dark:bg-primary-700 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    Volgende
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Trial;
