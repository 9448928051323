import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useApiService } from "./hooks/apiService";
import { ToggleSwitch } from "flowbite-react";

function toDatetimeLocalInputCompatibleLocalDate(date) {
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  const pad = (num) => String(num).padStart(2, "0");

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

function callOnErrorSetError(callback, setError) {
  return async () => {
    try {
      return await callback();
    } catch (error) {
      const responseErrors = error.response.data.errors;
      responseErrors.forEach((error) => {
        setError(error.loc[0], { type: error.type, message: error.msg });
      });
      throw error;
    }
  };
}

function OfferingForm({ chatbotUuid, successCallback, offeringId }) {
  const { apiService } = useApiService();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: async () => loadOfferings(),
  });

  // Watch payment_period to conditionally validate price and update periods
  const paymentPeriod = watch("payment_period");

  // Set periods default value based on payment_period and set price to 0 for onetime
  useEffect(() => {
    if (paymentPeriod === "yearly") {
      setValue("periods", 1);
    } else if (!paymentPeriod || paymentPeriod === "onetime") {
      setValue("periods", null);
      setValue("price", 0); // Set price to 0 when onetime is selected
    }
  }, [paymentPeriod, setValue]);

  async function loadOfferings() {
    if (!offeringId) {
      return {
        payment_period: "onetime",
        active: true,
        price: 0,
        one_time_price: 0,
        periods: null,
      };
    }
    let offeringsRes = await apiService.getOffering(chatbotUuid, offeringId);
    // console.log(offeringsRes);
    for (const key in offeringsRes) {
      if (Object.hasOwnProperty.call(offeringsRes, key)) {
        let element = offeringsRes[key];
        // console.log("key", key, offeringsRes[key]);
        if (["start_date", "end_date"].includes(key)) {
          // convert the utc date back to local time
          let date = new Date(element);
          element = toDatetimeLocalInputCompatibleLocalDate(date);
          // console.log("element", element, key);
          offeringsRes[key] = element;
        }
      }
    }

    // Handle special case for yearly payment
    if (offeringsRes.payment_period === "yearly") {
      offeringsRes.periods = 1;
    }

    return offeringsRes;
  }

  const onSubmit = async (data) => {
    console.log(data.start_date, data.end_date);
    console.log(new Date().getTimezoneOffset());
    // toISOString() will convert the date to UTC time, no need to do anything special here
    data.start_date = data.start_date
      ? new Date(data.start_date).toISOString()
      : null;
    data.end_date = data.end_date
      ? new Date(data.end_date).toISOString()
      : null;
    if (data.one_time_price === "") {
      data.one_time_price = 0;
    }

    // Ensure price is 0 for onetime payment
    if (data.payment_period === "onetime") {
      data.price = 0;
    }

    console.log("data", data);
    if (offeringId) {
      let res = await toast.promise(
        callOnErrorSetError(async () => {
          return await apiService.updateOffering(chatbotUuid, offeringId, data);
        }, setError),
        {
          pending: "Updating offering... 📦",
          success: "Offering updated successfully 🎉",
          error: "Failed to update offering 😢",
        },
      );
      console.log("res", res);
    } else {
      let res = await toast.promise(
        callOnErrorSetError(async () => {
          return await apiService.addOffering(chatbotUuid, data);
        }, setError),
        {
          pending: "Adding new offering... 📦",
          success: "Offering added successfully 🎉",
          error: "Failed to add offering 😢",
        },
      );
      console.log("res", res);
    }

    successCallback();
  };

  // Generate options for periods based on payment_period
  const renderPeriodOptions = () => {
    if (paymentPeriod === "monthly") {
      return Array.from({ length: 12 }, (_, i) => i + 1).map((num) => (
        <option key={num} value={num}>
          {num}
        </option>
      ));
    } else if (paymentPeriod === "quarterly") {
      return Array.from({ length: 4 }, (_, i) => i + 1).map((num) => (
        <option key={num} value={num}>
          {num}
        </option>
      ));
    }
    return null;
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid gap-4 mb-4 sm:grid-cols-1 sm:gap-6 sm:mb-5"
    >
      <div>
        <label
          htmlFor="name"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Naam
        </label>
        <input
          type="text"
          id="name"
          className="form-input mt-1 block w-full rounded-lg"
          {...register("name", { required: "Offering name is required" })}
        />
        {errors.name && (
          <span className="text-red-500">{errors.name.message}</span>
        )}
      </div>
      <div>
        <label
          htmlFor="description"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Beschrijving
        </label>
        <textarea
          id="description"
          rows={3}
          className="form-textarea mt-1 block w-full rounded-lg"
          {...register("description", {
            required: "Description is required",
          })}
        ></textarea>
        {errors.description && (
          <span className="text-red-500">{errors.description.message}</span>
        )}
      </div>
      <div>
        <label
          htmlFor="one_time_price"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Eenmalige prijs
        </label>
        <input
          type="number"
          min="0"
          step="0.01"
          id="one_time_price"
          className="form-input mt-1 block w-full rounded-lg"
          {...register("one_time_price")}
        />
        {errors.one_time_price && (
          <span className="text-red-500">{errors.one_time_price.message}</span>
        )}
      </div>
      <div>
        <label
          htmlFor="payment_period"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Betaal frequentie
        </label>
        <select
          id="payment_period"
          className="form-select mt-1 block w-full rounded-lg"
          {...register("payment_period")}
        >
          <option value="onetime">Geen periodieke betaling</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
          <option value="quarterly">Quarterly</option>
        </select>
        {errors.payment_period && (
          <span className="text-red-500">{errors.payment_period.message}</span>
        )}
      </div>

      {/* New periods field - only shown for relevant payment periods */}
      {paymentPeriod && paymentPeriod !== "onetime" && (
        <div>
          <label
            htmlFor="periods"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Aantal periodes
          </label>
          {paymentPeriod === "yearly" ? (
            <input
              type="text"
              id="periods"
              disabled
              value="1"
              className="form-input mt-1 block w-full rounded-lg bg-gray-100"
              {...register("periods")}
            />
          ) : (
            <select
              id="periods"
              className="form-select mt-1 block w-full rounded-lg"
              {...register("periods", {
                required: "Number of periods is required",
              })}
            >
              <option value="">Selecteer aantal periodes</option>
              {renderPeriodOptions()}
            </select>
          )}
          {errors.periods && (
            <span className="text-red-500">{errors.periods.message}</span>
          )}
        </div>
      )}

      <div>
        <label
          htmlFor="price"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Periodieke Prijs
        </label>
        <input
          type="number"
          min="0"
          step="0.01"
          id="price"
          className={`form-input mt-1 block w-full rounded-lg ${
            paymentPeriod === "onetime" ? "bg-gray-100" : ""
          }`}
          disabled={paymentPeriod === "onetime"}
          value={paymentPeriod === "onetime" ? 0 : undefined}
          {...register("price", {
            required:
              paymentPeriod !== "onetime"
                ? "Price is required when payment period is selected"
                : false,
          })}
        />
        {errors.price && (
          <span className="text-red-500">{errors.price.message}</span>
        )}
      </div>
      <div>
        <label
          htmlFor="more_information_url"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Meer informatie URL
        </label>
        <input
          type="url"
          id="more_information_url"
          className="form-input mt-1 block w-full rounded-lg"
          {...register("more_information_url")}
        />
        {errors.more_information_url && (
          <span className="text-red-500">
            {errors.more_information_url.message}
          </span>
        )}
      </div>
      <div>
        <label
          htmlFor="start_date"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Start datum
        </label>
        <input
          type="datetime-local"
          id="start_date"
          className="form-input mt-1 block w-full rounded-lg"
          {...register("start_date")}
        />
        {errors.start_date && (
          <span className="text-red-500">{errors.start_date.message}</span>
        )}
      </div>
      <div>
        <label
          htmlFor="end_date"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Eind datum
        </label>
        <input
          type="datetime-local"
          id="end_date"
          className="form-input mt-1 block w-full rounded-lg"
          {...register("end_date")}
        />
        {errors.end_date && (
          <span className="text-red-500">{errors.end_date.message}</span>
        )}
      </div>
      <div>
        <label
          htmlFor="end_date"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Actief
        </label>
        <Controller
          name="active"
          control={control}
          render={({ field }) => (
            <ToggleSwitch
              label="Aanzetten om actief te maken, start en eind datum worden gehandhaafd"
              checked={field.value}
              onChange={(value) => {
                field.onChange(value);
              }}
            />
          )}
        />
        {errors.active && (
          <span className="text-red-500">{errors.active.message}</span>
        )}
      </div>
      <button
        type="submit"
        className="mt-4 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
      >
        Opslaan
      </button>
    </form>
  );
}

export default OfferingForm;
