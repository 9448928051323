import React from "react";
import { SITE_URL } from "./constants";
import { Link } from "react-router-dom";
import emailToGravatar from "./utils/gravatar";
import useUserStore from "./hooks/user-store";
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import { useSidebarContext } from "./SidebarContext";
import { Menu, X } from "lucide-react";
import isSmallScreen from "./lib/is-small-screen";
import CompanyLogo from "./components/CompanyLogo";

const DashboardNavbar = () => {
  const userData = useUserStore((state) => state.userData);
  const { isOpenOnSmallScreens, isPageWithSidebar, setOpenOnSmallScreens } =
    useSidebarContext();

  return (
    <Navbar
      fluid
      // className="fixed top-0 z-30 w-full border-b border-gray-200 bg-white p-0 sm:p-0 dark:border-gray-700 dark:bg-gray-800"
      className="fixed bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 left-0 right-0 top-0 z-50"
    >
      <div className="flex items-center">
        {isPageWithSidebar && (
          <button
            onClick={() => setOpenOnSmallScreens(!isOpenOnSmallScreens)}
            className="mr-3 cursor-pointer rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:inline"
          >
            <span className="sr-only">Toggle sidebar</span>
            {isOpenOnSmallScreens && isSmallScreen() ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        )}

        <Navbar.Brand as={Link} to={SITE_URL}>
          <CompanyLogo />
        </Navbar.Brand>
      </div>
      <div className="flex md:order-2">
        <Dropdown
          arrowIcon={false}
          inline
          label={
            <Avatar
              alt="User settings"
              img={emailToGravatar(userData?.email)}
              rounded
            />
          }
        >
          <Dropdown.Header>
            <span className="block text-sm">{userData.fullname}</span>
            <span className="block truncate text-sm font-medium">
              {userData.email}
            </span>
          </Dropdown.Header>
          {/* <Dropdown.Item>Dashboard</Dropdown.Item> */}
          {/* <Dropdown.Item>Settings</Dropdown.Item> */}
          {/* <Dropdown.Item>Earnings</Dropdown.Item> */}
          <Dropdown.Item as={Link} to="/dashboard/profile">
            Profiel
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/logout">
            Uitloggen
          </Dropdown.Item>
        </Dropdown>
        {/* <Navbar.Toggle /> */}
      </div>
    </Navbar>
  );
};

export default DashboardNavbar;
