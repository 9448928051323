import React from "react";
import { Spinner } from "flowbite-react";

const Loading = ({ loading }) => {
  if (loading === false) {
    return null;
  }

  return (
    <div className="flex justify-center">
      <Spinner />
    </div>
  );
};

export default Loading;
