import React, { useEffect, useState } from "react";
import { useApiService } from "./hooks/apiService";
import OfferingDeleteModal from "./OfferingDeleteModal";
import ClockIcon from "./icons/ClockIcon";
import RemoveIcon from "./icons/RemoveIcon";
import OfferingAddModal from "./OfferingAddModal";
import ExternalLinkIcon from "./icons/ExternalLinkIcon";
import EditIcon from "./icons/EditIcon";
import OfferingEditModal from "./OfferingEditModal";
import { Link } from "react-router-dom";
import Loading from "./Loading";

/**
 * @typedef {Object} Offering
 * @property {number} id
 * @property {number} chatbot_id
 * @property {string} name
 * @property {string} description
 * @property {number} price
 * @property {number} one_time_price
 * @property {string} payment_period
 * @property {string} start_date
 * @property {string} end_date
 * @property {boolean} active
 * @property {string} more_information_url
 * @property {boolean} is_recurring
 */

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const Offerings = () => {
  const { apiService } = useApiService();
  /**
   * @type {[Offering[], function(Offering[]): void]}
   */
  const [offerings, setOfferings] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [deleteOfferingId, setDeleteOfferingId] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [chatbotUuid, setChatbotUuid] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const [hasMollieConnect, setHasMollieConnect] = useState(false);

  async function loadOfferings() {
    if (showAddModal) {
      return;
    }
    setLoading(true);
    let settingsPromise = apiService.getMyChatbotSettings();
    // status check if the user has a mollie connect
    let connectPromise = apiService.hasMollieConnect();
    let response = await settingsPromise;
    let connectResponse = await connectPromise;
    setHasMollieConnect(connectResponse.is_connected);
    const firstChatbot = response[0];
    setChatbotUuid(firstChatbot.uuid);
    let offeringsRes = await apiService.getChatbotOfferings(firstChatbot.uuid);
    // await sleep(1000);
    // console.log(offeringsRes);
    setOfferings(offeringsRes);
    setLoading(false);
  }
  useEffect(() => {
    loadOfferings();
    return () => {
      setLoading(false);
    };
  }, [showEditModal, showDeleteModal, showAddModal]);

  const deleteOffering = async () => {
    console.log("deleteOfferingId", deleteOfferingId);
    console.log(offerings);
    await apiService.deleteOffering(chatbotUuid, deleteOfferingId);
    // just delete offering without reloading the rest of the offerings
    setOfferings(
      offerings.filter((offering) => offering.id !== deleteOfferingId),
    );

    setShowDeleteModal(false);
    // loadOfferings();
  };

  if (!hasMollieConnect) {
    return (
      <div className="mb-4 bg-white rounded shadow p-6">
        <h2 className="mb-3 text-xl font-bold text-gray-900 dark:text-white">
          Klant Aanbiedingen
        </h2>
        <p>
          Voordat je klant aanbiedingen kunt toevoegen, moet je eerst een Mollie
          Connect opzetten. Je kan je bestaande Mollie account gebruiken of een
          nieuwe aanmaken. Op deze manier kunnen je klanten je betalen.{" "}
          <Link
            to="/dashboard/mollie_redirect"
            className="text-blue-500 hover:underline dark:text-blue-400"
          >
            Ga naar de Mollie Connect pagina om te beginnen
          </Link>
        </p>
      </div>
    );
  }

  return (
    <>
      <OfferingDeleteModal
        show={showDeleteModal}
        setShowCallback={setShowDeleteModal}
        deleteCallback={deleteOffering}
      />
      <OfferingAddModal
        chatbotUuid={chatbotUuid}
        show={showAddModal}
        setShowCallback={setShowAddModal}
      />
      <OfferingEditModal
        chatbotUuid={chatbotUuid}
        show={showEditModal !== false}
        editOfferingId={showEditModal}
        setShowCallback={setShowEditModal}
      />
      <div className="mb-4 bg-white rounded shadow p-6">
        <h2 className="mb-3 text-xl font-bold text-gray-900 dark:text-white">
          Klant Aanbiedingen
        </h2>
        {!hasMollieConnect && (
          <p>
            Voordat je klant aanbiedingen kunt toevoegen, moet je eerst een
            Mollie Connectie maken. Op deze manier kunnen je klanten je betalen.{" "}
            <Link
              to="/dashboard/mollie_redirect"
              className="text-blue-500 hover:underline dark:text-blue-400"
            >
              Mollie Connect
            </Link>
          </p>
        )}
        {hasMollieConnect && (
          <p className="text-blue-600 hover:underline mb-4">
            <Link to="/dashboard/mollie_redirect">Mollie Connect status</Link>
          </p>
        )}
        <div className="flex items-end space-x-4">
          <button
            onClick={() => setShowAddModal(true)}
            className={`text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${isLoading && "cursor-not-allowed opacity-50"}`}
            disabled={isLoading}
          >
            Voeg een klant aanbieding toe
          </button>
        </div>
        <section className="bg-white antialiased dark:bg-gray-900">
          <div className="max-w-screen-xl">
            <div className="mt-3 sm:mt-4 lg:flex lg:items-start">
              <div className="mx-auto w-full flex-none">
                <div className="space-y-6">
                  <Loading loading={isLoading} />
                  {offerings.map((offering) => (
                    <div
                      key={offering.id}
                      className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 md:p-6"
                    >
                      <div className="space-y-4 md:flex md:items-center md:justify-between md:gap-6 md:space-y-0">
                        <div className="flex items-center justify-between md:order-3 md:justify-end">
                          <div className="text-end md:order-4 md:w-32">
                            <p className="text-base font-bold text-gray-900 dark:text-white">
                              {offering.one_time_price > 0 && (
                                <span>
                                  eenmalig &euro; {offering.one_time_price}
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="text-end md:order-4 md:w-32">
                            <p className="text-base font-bold text-gray-900 dark:text-white">
                              &euro; {offering.price} per{" "}
                              {offering.payment_period}
                            </p>
                          </div>
                        </div>

                        <div className="w-full min-w-0 flex-1 space-y-4 md:order-2 md:max-w-md">
                          <a
                            onClick={() => {
                              console.log("edit", offering.id);
                            }}
                            className="text-base font-medium text-gray-900 hover:underline dark:text-white"
                          >
                            {offering.name}
                          </a>
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            {offering.description}
                          </p>

                          <div className="flex items-center gap-4">
                            {/* <span> */}
                            {/*   {offering.start_date} - {offering.end_date} */}
                            {/* </span> */}
                            <span
                              type="button"
                              className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                            >
                              <ClockIcon />
                              Betaal periode: {offering.payment_period}
                            </span>

                            <button
                              type="button"
                              className="inline-flex items-center text-sm font-medium text-red-600 hover:underline dark:text-red-500"
                              onClick={() => {
                                setDeleteOfferingId(offering.id);
                                setShowDeleteModal(true);
                              }}
                            >
                              <RemoveIcon />
                              Verwijder
                            </button>
                            <button
                              type="button"
                              className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                              onClick={() => {
                                setShowEditModal(offering.id);
                              }}
                            >
                              <EditIcon />
                              Wijzigen
                            </button>
                            {offering.more_information_url && (
                              <button
                                type="button"
                                className="inline-flex items-center text-sm font-medium text-gray-500 hover:underline dark:text-gray-400 dark:hover:text-white"
                              >
                                <ExternalLinkIcon />
                                <a
                                  href={offering.more_information_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Meer informatie
                                </a>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <pre>{JSON.stringify(offerings, 0, 4)}</pre> */}
      </div>
    </>
  );
};

export default Offerings;
