import React from "react";
import { getStatusIcon } from "./Order";
import { formatDate } from "./utils/date";

const Timeline = ({ tasks }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-lg font-semibold mb-4 text-gray-800">Tijdlijn</h2>
      <div className="relative">
        <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-gray-200"></div>
        <div className="space-y-6">
          {tasks.map((task) => (
            <div key={`timeline-${task.id}`} className="relative pl-10">
              <div
                className={`absolute left-0 top-0 w-8 h-8 rounded-full flex items-center justify-center ${
                  task.status === "done"
                    ? "bg-green-100"
                    : task.status === "in_progress"
                      ? "bg-blue-100"
                      : task.status === "failed"
                        ? "bg-red-100"
                        : task.status === "skipped"
                          ? "bg-gray-100"
                          : "bg-gray-100"
                }`}
              >
                {getStatusIcon(task.status)}
              </div>
              <div>
                <h3 className="font-medium text-gray-800">{task.name}</h3>
                <p className="text-sm text-gray-500">
                  {task.status === "done"
                    ? `Voltooid op ${formatDate(task.ended_at || task.updated_at)}`
                    : task.status === "in_progress"
                      ? `Gestart op ${formatDate(task.started_at || task.updated_at)}`
                      : task.status === "failed"
                        ? `Mislukt op ${formatDate(task.updated_at)}`
                        : task.status === "skipped"
                          ? `Overgeslagen op ${formatDate(task.updated_at)}`
                          : `Wachtend op uitvoering`}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
