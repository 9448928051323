import React from "react";
import siteChatProLogo from "../assets/sitechatpro-logo.png";

import { COMPANY_NAME } from "@/constants";

const CompanyLogo = () => {
  return (
    <img
      src={siteChatProLogo}
      className="mr-3 h-12"
      alt={`${COMPANY_NAME} Logo`}
    />
  );
};

export default CompanyLogo;
