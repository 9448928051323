import { useAuth0 } from "@auth0/auth0-react";
import ApiService from "../api/ApiService";
import { AUTH0_AUDIENCE, AUTH0_SCOPE } from "../constants";

export const useApiService = () => {
  const { getAccessTokenSilently } = useAuth0();
  const apiService = new ApiService();
  const wrappedGetAccessTokenSilently = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: AUTH0_AUDIENCE,
        scope: AUTH0_SCOPE,
      },
    });
    return accessToken;
  };
  apiService.setFetchTokenCallback(wrappedGetAccessTokenSilently);

  return { apiService };
};
