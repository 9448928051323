import React from "react";
import { Alert } from "flowbite-react";
import { Link, useNavigate, useRouteError } from "react-router-dom";

const ErrorPage = () => {
  const error = useRouteError();
  if (error) {
    console.error(error);
  }
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-6xl font-bold">Oeps!</h1>
        <p className="mb-4 text-2xl">404 - Pagina Niet Gevonden</p>
        <p className="mb-4">
          De pagina die je zoekt is mogelijk verwijderd, heeft een andere naam
          gekregen of is tijdelijk niet beschikbaar.
        </p>

        <Alert color="red">
          <span>
            Probeer alstublieft{" "}
            <a
              href="#"
              onClick={() => {
                navigate(-1);
              }}
              className="underline"
            >
              terug te gaan
            </a>{" "}
            of keer terug naar de{" "}
            <Link to="/" className="underline">
              startpagina
            </Link>
            .
          </span>
        </Alert>
      </div>
    </div>
  );
};

export default ErrorPage;
