import React from "react";
import QuestionIcon from "./icons/QuestionIcon";
import { Link } from "react-router-dom";

const questions = [
  {
    question: "Wat gebeurt er nadat een klant een website heeft gekocht?",
    answer: (
      <>
        <p className="text-gray-500 dark:text-gray-400">
          Nadat een klant een website heeft gekocht, wordt er een
          bevestigingsmail naar de klant gestuurd met de informatie over de
          website die betaald is. Daarin staat:
        </p>
        <ul className="list-inside list-disc">
          <li>
            Een link naar de tijdelijke &lt;subdomain&gt;.sitechatpro.nl
            website.
          </li>
          <li>De gewenste domeinnaam.</li>
          <li>Een link naar de factuur.</li>
        </ul>
        <p className="text-gray-500 dark:text-gray-400">
          Er wordt ook naar u een mail gestuurd met het verzoek om de website te
          migreren vanaf &lt;subdomain&gt;.sitechatpro.nl naar de gewenste
          domeinnaam. TODO: Add more information about the migration process.
        </p>
      </>
    ),
  },
  {
    question: "Wat zijn Klant Aanbiedingen?",
    answer: (
      <>
        <p className="text-gray-500 dark:text-gray-400">
          Klant Aanbiedingen zijn aanbiedingen die u aan uw klant wilt tonen aan
          het einde van een chat sessie. Deze aanbiedingen verschijnen in de
          chatbot direct na het genereren van een website. Uw klant kan hierop
          doorklikken naar uw site en de aanbieding bekijken. U kunt klant
          aanbiedingen aanmaken in het dashboard onder het kopje{" "}
          <Link
            className="text-blue-600 hover:underline"
            to="/dashboard/offerings"
          >
            Klant Aanbiedingen
          </Link>
          .
        </p>
      </>
    ),
  },
  {
    question: "Wanneer wordt een gebruiker een Lead?",
    answer: (
      <>
        <p className="text-gray-500 dark:text-gray-400">
          Een gebruiker wordt een Lead wanneer deze zich heeft aangemeld om de
          chat te gebruiken. Dit gebeurt wanneer de gebruiker een chat start met
          de chatbot op uw website. Indien de gebruiker een website heeft
          gegenereerd wordt de lead verrijkt met de gegevens van de website. U
          kunt deze leads bekijken in het menu onder het kopje{" "}
          <Link className="text-blue-600 hover:underline" to="/dashboard/leads">
            Leads
          </Link>
          .
        </p>
      </>
    ),
  },
  {
    question: "Wat zijn Chats?",
    answer: (
      <>
        <p className="text-gray-500 dark:text-gray-400">
          In dit overzicht ziet u alle chats die klanten via uw chatbot hebben
          gevoerd. U kunt hier de chats bekijken om te zien wat er besproken is.
          U vindt dit overzicht in het menu onder het kopje{" "}
          <Link className="text-blue-600 hover:underline" to="/dashboard/leads">
            Chats
          </Link>
          .
        </p>
      </>
    ),
  },
  {
    question: "Kan ik een feature request indienen?",
    answer: (
      <>
        <p className="text-gray-500 dark:text-gray-400">
          Ja, u kunt een feature request indienen door een e-mail te sturen naar{" "}
          <a
            className="text-blue-600 hover:underline"
            href="mailto:support@sitechatpro.nl"
          >
            support@sitechatpro.nl
          </a>
          . Indien er genoeg animo is voor een feature wordt deze
          geimplementeerd en zullen wij u op de hoogte houden van de voortgang.
        </p>
      </>
    ),
  },
];

export default function Faq() {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="bg-white rounded shadow p-6">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          Veelgestelde vragen
        </h2>
        <div className="grid pt-8 text-left border-t border-gray-200 md:gap-4 dark:border-gray-700 md:grid-cols-2">
          {questions.map((q, i) => (
            <div key={i} className="mb-10">
              <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                <QuestionIcon />
                {q.question}
              </h3>
              {q.answer}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
