import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useApiService } from "@/hooks/apiService";
import useUserStore from "@/hooks/user-store";
import { Popover } from "flowbite-react";

const toAgo = (dateString) => {
  // accepts 2025-03-11T12:08:26.309661+00:00
  const date = new Date(dateString);
  const now = new Date();
  const diff = now - date;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(weeks / 4);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return `${years} jaar geleden`;
  }
  if (months > 0) {
    return `${months} maanden geleden`;
  }
  if (weeks > 0) {
    return `${weeks} weken geleden`;
  }
  if (days > 0) {
    return `${days} dagen geleden`;
  }
  if (hours > 0) {
    return `${hours} uur geleden`;
  }
  if (minutes > 0) {
    return `${minutes} minuten geleden`;
  }
  if (seconds > 0) {
    return `${seconds} seconden geleden`;
  }
  return "nu";
};

const RecentOrders = () => {
  const [orders, setOrders] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { apiService } = useApiService();
  const chatbotUUID = useUserStore((state) => state?.userData?.chatbot?.uuid);

  async function getOrdersData() {
    if (chatbotUUID !== undefined) {
      setLoading(true);
      let data = await apiService.getOrders(chatbotUUID, 1, 10);
      console.log(data);
      setOrders(data.orders);
      setLoading(false);
    }
  }

  useEffect(() => {
    getOrdersData();
  }, [chatbotUUID]);

  return (
    <div className="w-full p-4 bg-white border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
      <div className="flex items-center justify-between mb-4">
        <Popover
          trigger="hover"
          content={<div className="p-6">Hier ziet u de laatste 10 orders.</div>}
        >
          <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
            Recente orders
          </h5>
        </Popover>
        <Link
          to="/dashboard/orders"
          className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
        >
          Alle orders bekijken
        </Link>
      </div>
      <div className="flow-root">
        {loading && <p>Loading...</p>}
        {orders.length === 0 && !loading && (
          <p>
            Helaas zijn er nog geen orders! Plaats uw chatbot op een prominente
            pagina op uw website en zorg dat uw marketing kanalen goed
            ingeregeld zijn zodat uw chatbot de maximale aandacht krijgt die het
            verdiend!
          </p>
        )}
        <ul
          role="list"
          className="divide-y divide-gray-200 dark:divide-gray-700"
        >
          {orders.map((order, index) => (
            // last li should have pt-3 pb-0 sm:pt-4
            <li
              key={index}
              className={
                index === orders.length - 1
                  ? "pt-3 pb-0 sm:pt-4"
                  : "py-3 sm:py-4"
              }
            >
              <div className="flex items-center">
                <div className="flex-1 min-w-0 ms-4">
                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                    <Link
                      to={`/dashboard/orders/${order.id}`}
                      className="hover:underline"
                    >
                      {order.site_config.target_domain}
                    </Link>
                  </p>
                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                    {toAgo(order.created_at)}
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  {order.payment?.status === "paid" && (
                    <>{order.payment?.amount?.toFixed(2) || "-"} €</>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RecentOrders;
