import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./MollieRedirect.css";
import {
  MOLLIE_AUTH_ENDPOINT,
  MOLLIE_CLIENT_ID,
  MOLLIE_REDIRECT_URI,
} from "./constants";
import MollieIcon from "./icons/MollieIcon";
import createUrl from "./utils/url";
import { useApiService } from "./hooks/apiService";
import Loading from "./Loading";
import MollieProfileSelector from "./MollieProfileSelector";

const MollieRedirect = () => {
  const [searchParams] = useSearchParams();
  const { apiService } = useApiService();
  const [isLoading, setIsLoading] = useState(true);
  const [hasMollieConnect, setHasMollieConnect] = useState(false);
  const [connectSuccess, setConnectSuccess] = useState(null);
  const [onboardingStatus, setOnboardingStatus] = useState(null);

  const storedMollieState = sessionStorage.getItem("mollieState");

  // get code, state, error, error_description from query params
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const error = searchParams.get("error");
  const errorDescription = searchParams.get("error_description");

  // verify state
  // cancel state: {"code":null,"state":"fc4ili","error":"access_denied","errorDescription":"The user denied access to your application"}
  // otherwise if there is a code, use that to call the mollie oauth2/tokens endpoint to get the access token
  // send the code to the backend, which will then send a request to the mollie oauth2/tokens endpoint and store the access token in the database
  // read: https://docs.mollie.com/reference/generate-tokens
  if (state) {
    if (state !== storedMollieState) {
      console.log(storedMollieState, state);
      console.error("State mismatch, possible CSRF attack");
      return <div>State mismatch, possible CSRF attack</div>;
    }
  }

  useEffect(() => {
    const checkMollieConnection = async () => {
      setIsLoading(true);
      console.log("calling check mollie connection");
      let connectPromise = apiService.hasMollieConnect();
      let connectResponse = await connectPromise;
      setHasMollieConnect(connectResponse.is_connected);
      setIsLoading(false);
    };
    checkMollieConnection();
  }, [connectSuccess]);

  useEffect(() => {
    if (code) {
      const saveMollieAuthCode = async () => {
        console.log("calling save mollie auth code");
        let data = await apiService.saveMollieAuthCode(code);
        console.log("data", data);
        setConnectSuccess(true);
      };
      saveMollieAuthCode();
    }
  }, [code]);

  useEffect(() => {
    const getOnboardingStatus = async () => {
      console.log("calling get onboarding status");
      let data = await apiService.getMollieOnboardingStatus();
      setOnboardingStatus(data);
      console.log("data", data);
    };
    if (hasMollieConnect) {
      getOnboardingStatus();
    }
  }, [hasMollieConnect]);

  const mollieData = { code, state, error, errorDescription };
  console.log(JSON.stringify(mollieData));

  // once connected succesfully, don't show the Mollie Connect button anymore, show the connection details.
  // TODO: when I change scopes, I need to invalidate all user tokens and let them reconnect, is there a mollie docs page for this?

  const handleMollieConnect = () => {
    const randomState = Math.random().toString(36).substring(7);
    // store randomState in session storage
    sessionStorage.setItem("mollieState", randomState);
    console.log("mollieState", randomState);
    // See: https://docs.mollie.com/reference/authorize
    const mollieAuthUrl = createUrl(MOLLIE_AUTH_ENDPOINT, {
      client_id: MOLLIE_CLIENT_ID,
      redirect_uri: MOLLIE_REDIRECT_URI,
      // https://docs.mollie.com/docs/connect-platforms-setting-up-oauth#permissions
      scope:
        "onboarding.read organizations.read profiles.read payments.write payments.read customers.write subscriptions.write",
      response_type: "code",
      state: randomState,
    });
    window.location.href = mollieAuthUrl;
  };

  if (isLoading) {
    return (
      <div className="mb-4 bg-white rounded shadow p-6">
        <h2 className="mb-3 text-xl font-bold text-gray-900 dark:text-white">
          Mollie Connect
        </h2>
        <Loading />
      </div>
    );
  }

  return (
    <div className="mb-4 bg-white rounded shadow p-6">
      <h2 className="mb-3 text-xl font-bold text-gray-900 dark:text-white">
        Mollie Connect
      </h2>
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorDescription}</span>
          <span className="block sm">
            <br />
            Neem contact op voor support of indien u zelf geannuleerd heeft kunt
            u het nogmaals proberen wanneer u er klaar voor bent.
          </span>
        </div>
      )}
      {!hasMollieConnect && !connectSuccess && (
        <button onClick={handleMollieConnect} className="mollie-button">
          Connect with
          <MollieIcon />
        </button>
      )}
      {connectSuccess && (
        <div
          className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <p>U heeft succesvol verbinding gemaakt met Mollie Connect.</p>
        </div>
      )}
      {hasMollieConnect && (
        <div className="mt-6 space-y-4 border-b border-t border-gray-200 py-8 dark:border-gray-700 sm:mt-8">
          <p>✅ Uw Mollie Connect koppeling is actief.</p>
          <h4 className="text-lg font-semibold text-gray-900 dark:text-white">
            Uw Mollie gegevens:
          </h4>

          {onboardingStatus && (
            <dl>
              <dt className="text-base font-medium text-gray-900 dark:text-white">
                Naam:
              </dt>
              <dd className="mb-4 mt-1 text-base font-normal text-gray-500 dark:text-gray-400">
                {onboardingStatus?.name}
              </dd>
              <dt className="text-base font-medium text-gray-900 dark:text-white">
                Kan betalingen ontvangen:
              </dt>
              <dd className="mb-4 mt-1 text-base font-normal text-gray-500 dark:text-gray-400">
                {onboardingStatus?.canReceivePayments
                  ? "✅"
                  : "❌, ga naar het Mollie dashboard om de Mollie onboarding af te maken zodat u betalingen van uw klanten kunt ontvangen. Zonder deze instelling kan SiteChatPro geen betaling aanbieden aan uw klanten."}
              </dd>
              <dt className="text-base font-medium text-gray-900 dark:text-white">
                Kan settlements ontvangen:
              </dt>
              <dd className="mb-4 mt-1 text-base font-normal text-gray-500 dark:text-gray-400">
                {onboardingStatus?.canReceiveSettlements
                  ? "✅"
                  : "❌, ga naar het Mollie dashboard om de Mollie onboarding af te maken zodat u betalingen van uw klanten kunt ontvangen. Zonder deze instelling kan Mollie geen betalingen aan u uitbetalen."}
              </dd>

              <MollieProfileSelector />
              <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                Via deze link gaat u in een nieuw tabblad naar het Mollie
                Dashboard voor meer informatie over uw Mollie account:{" "}
                <a
                  href={onboardingStatus._links.dashboard.href}
                  target="_blank"
                  className="text-base font-medium text-primary-700 hover:underline dark:text-primary-500"
                >
                  Mollie dashboard.
                </a>
              </p>
            </dl>
          )}
        </div>
      )}
    </div>
  );
};

export default MollieRedirect;
