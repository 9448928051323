import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ExternalLinkIcon from "@/icons/ExternalLinkIcon";
import { useApiService } from "@/hooks/apiService";
import useUserStore from "@/hooks/user-store";
import emailToGravatar from "@/utils/gravatar";
import { Popover } from "flowbite-react";
import ObscuredEmail from "@/utils/email";

/*
 * Get top N leads that have a site_url associated with them
 * @param {Array} leads - Array of leads
 * @param {Number} n - Number of leads to return
 * @returns {Array} - Array of top N leads
 * */
const topNleadsWithSite = (leads, n) => {
  return leads.filter((lead) => lead.site_configs?.length > 0).slice(0, n);
};

const RecentLeads = () => {
  const [leads, setLeads] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { apiService } = useApiService();
  const chatbotUUID = useUserStore((state) => state?.userData?.chatbot?.uuid);

  async function getLeadsData() {
    if (chatbotUUID !== undefined) {
      setLoading(true);
      let data = await apiService.getLeads(chatbotUUID, 1);
      setLeads(topNleadsWithSite(data, 10));
      setLoading(false);
    }
  }

  useEffect(() => {
    getLeadsData();
  }, [chatbotUUID]);

  return (
    <div className="w-full p-4 bg-white border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
      <div className="flex items-center justify-between mb-4">
        <Popover
          trigger="hover"
          content={
            <div className="p-6">
              Hier ziet u voor de laatste 10 leads, de gegenereerde websites per
              lead
            </div>
          }
        >
          <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
            Recente site generaties
          </h5>
        </Popover>
        <Link
          to="/dashboard/leads"
          className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
        >
          Alle leads bekijken
        </Link>
      </div>
      <div className="flow-root">
        {loading && <p>Loading...</p>}
        {leads.length === 0 && !loading && (
          <p>
            Helaas zijn er nog geen leads! Plaats uw chatbot op een prominente
            pagina op uw website en zorg dat uw marketing kanalen goed
            ingeregeld zijn zodat uw chatbot de maximale aandacht krijgt die het
            verdiend!
          </p>
        )}
        <ul
          role="list"
          className="divide-y divide-gray-200 dark:divide-gray-700"
        >
          {leads.map((lead, index) => (
            // last li should have pt-3 pb-0 sm:pt-4
            <li
              key={lead.lead_id}
              className={
                index === leads.length - 1
                  ? "pt-3 pb-0 sm:pt-4"
                  : "py-3 sm:py-4"
              }
            >
              <div key={lead.lead_id} className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="w-8 h-8 rounded-full"
                    src={emailToGravatar(lead.chat_user.username)}
                    alt={`Profile picture of ${lead.name}`}
                  ></img>
                </div>
                <div className="flex-1 min-w-0 ms-4">
                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                    {lead.site_configs.map((site) => (
                      <a
                        key={site.site_id}
                        href={site.site_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline text-blue-600 dark:text-blue-500 block"
                        title={site.site_url}
                      >
                        {site.site_url}
                        <ExternalLinkIcon className="inline w-3" />
                      </a>
                    ))}
                  </p>
                  <ObscuredEmail
                    email={lead.chat_user.username}
                    className="text-sm text-gray-500 truncate dark:text-gray-400"
                  />
                </div>
                {/* <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white"> */}
                {/*   {lead.site_configs?.length > 0 && ( */}
                {/*     <a */}
                {/*       href={lead.site_configs[0].site_url} */}
                {/*       target="_blank" */}
                {/*       rel="noopener noreferrer" */}
                {/*       className="hover:underline text-blue-600 dark:text-blue-500" */}
                {/*       title={lead.site_configs[0].site_url} */}
                {/*     > */}
                {/*       <ExternalLinkIcon /> */}
                {/*     </a> */}
                {/*   )} */}
                {/* </div> */}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RecentLeads;
