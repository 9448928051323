import React from "react";
import { Outlet } from "react-router-dom";
import withRequiresAuth from "./hoc/requires-auth";
import StoreInitializer from "./StoreInitializer";
import DashboardSidebar from "./DashboardSidebar";
import DashboardNavbar from "./DashboardNavbar";
import { useSidebarContext } from "./SidebarContext";
import { twJoin } from "tailwind-merge";
import IncompleteSettingsAlerts from "./IncompleteSettingsAlerts";

function Dashboard() {
  const { isOpenOnSmallScreens: isSidebarOpen } = useSidebarContext();

  return (
    <>
      <DashboardNavbar />

      <div className="pt-16 flex items-start">
        <DashboardSidebar />
        {/* <SidebarSearch /> */}
        {/* <SidebarBottomMenu /> */}

        <main
          className={twJoin(
            "overflow-y-auto relative w-full h-full bg-gray-50 dark:bg-gray-900 p-4",
            isSidebarOpen ? "lg:ml-16" : "lg:ml-64",
          )}
        >
          <IncompleteSettingsAlerts />
          <Outlet />
          <StoreInitializer />
        </main>
      </div>
    </>
  );
}

export default withRequiresAuth(Dashboard);
