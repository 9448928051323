import React from "react";
import RecentLeads from "@/components/dashboard/RecentLeads";
import RecentOrders from "./RecentOrders";

const DashboardHome = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 mb-4">
      <RecentLeads />
      <RecentOrders />
    </div>
  );
};

export default DashboardHome;
