import { Modal } from "flowbite-react";
import React from "react";

const OfferingDeleteModal = ({ show, setShowCallback, deleteCallback }) => {
  return (
    <Modal show={show} popup dismissible onClose={() => setShowCallback(false)}>
      <Modal.Header className="mb-3 text-2xl font-bold text-gray-900 dark:text-white">
        Klant aanbieding verwijderen?
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4 text-sm font-light text-gray-500 dark:text-gray-400">
          <p>
            Weet u zeker dat u dit klant aanbieding wilt verwijderen? Dit kan
            niet ongedaan worden gemaakt. U kunt later altijd een nieuw voorstel
            aanmaken.
          </p>
        </div>
        <div className="justify-between items-center pt-0 space-y-4 sm:flex sm:space-y-0">
          <div className="items-center space-y-4 sm:space-x-4 sm:flex sm:space-y-0">
            <button
              id="close-modal"
              type="button"
              className="py-2 px-4 w-full text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 sm:w-auto hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              onClick={() => setShowCallback(false)}
            >
              Annuleren
            </button>
            <button
              id="confirm-button"
              type="button"
              className="py-2 px-4 w-full text-sm font-medium text-center text-white rounded-lg bg-red-700 sm:w-auto hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              onClick={deleteCallback}
            >
              Verwijderen
            </button>
          </div>
        </div>
      </Modal.Body>
      {/* </div> */}
    </Modal>
  );
};

export default OfferingDeleteModal;
