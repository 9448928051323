import React from "react";
import ClipboardIcon from "../icons/ClipboardIcon";
import { toast } from "react-toastify";

const InlineCopy = ({ children, value }) => {
  const copyToClipboard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(value);
    toast("Waarde gekopieerd naar klembord");
  };

  return (
    <>
      <div className="inline-flex">
        {children}
        <button onClick={copyToClipboard}>
          <ClipboardIcon className="w-4 hover:text-gray-700" />
        </button>
      </div>
    </>
  );
};

export default InlineCopy;
