import React from "react";
import { useState, useEffect } from "react";
import QuestionIcon from "@/icons/QuestionIcon";

const DomainRegistrationOptionForm = ({
  register,
  setValue,
  transipApiKeySet,
  domainRegistrationType,
}) => {
  const [showApiKey, setShowApiKey] = useState(false);
  const [hasStoredApiKey, setHasStoredApiKey] = useState(false);

  // if transipApiKeySet is true set setShowApiKey to true via useEffect
  useEffect(() => {
    if (transipApiKeySet || domainRegistrationType === "transip") {
      setShowApiKey(true);
    }
    if (transipApiKeySet) {
      setHasStoredApiKey(true);
    }
  }, [transipApiKeySet]);

  const handleClearApiKey = () => {
    // Implement actual API key clearing logic here
    setValue("transipApiKey", "", { shouldDirty: true });
    setValue("domainRegistrationType", "manual");
    setHasStoredApiKey(false);
    setShowApiKey(false);
  };

  const transipKnowledgeBase =
    "https://www.transip.nl/knowledgebase/77-de-transip-rest-api-gebruiken/?utm_source=knowledge%20base";

  return (
    <div className="sm:col-span-2 space-y-4">
      <label
        htmlFor="description"
        className="block text-sm font-medium text-gray-900 dark:text-white"
      >
        Domeinnaam Registratie
      </label>

      <p className="text-sm text-gray-600 dark:text-gray-300">
        Wanneer u de Mollie Connect koppelingsmethode gebruikt, kan de
        domeinnaam automatisch geregistreerd worden indien u dat wenst. Deze
        keuze kunt u hier instellen, standaard staat deze optie uit. U dient dan
        zelf de domeinnaam te registreren en de DNS instellingen te
        configureren.
      </p>

      <div className="space-y-2">
        <label className="flex items-center space-x-2">
          <input
            type="radio"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            name="domainRegistrationType"
            value="manual"
            {...register("domainRegistrationType", {
              required: true,
              onChange: (e) => setShowApiKey(e.target.value === "transip"),
            })}
          />
          <span className="text-sm text-gray-900 dark:text-gray-300">
            Handmatig
          </span>
        </label>

        <label className="flex items-center space-x-2">
          <input
            type="radio"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            name="domainRegistrationType"
            value="transip"
            {...register("domainRegistrationType", {
              required: true,
              onChange: (e) => setShowApiKey(e.target.value === "transip"),
            })}
          />
          <span className="text-sm text-gray-900 dark:text-gray-300">
            Automatisch via TransIP API
          </span>
        </label>
      </div>

      {showApiKey && (
        <div className="mt-4 space-y-4">
          {hasStoredApiKey ? (
            <div className="space-y-2">
              <div className="p-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400">
                TransIP API key is geconfigureerd
              </div>
              <button
                type="button"
                onClick={handleClearApiKey}
                className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
              >
                API Key verwijderen
              </button>
            </div>
          ) : (
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <label
                  htmlFor="transipApiKey"
                  className="block text-sm font-medium text-gray-900 dark:text-white"
                >
                  TransIP API Key
                </label>
                <div className="group relative">
                  <QuestionIcon className="cursor-help hover:text-gray-700 dark:hover:text-gray-300" />
                  <div className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-300 absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-72">
                    <div className="bg-gray-900 p-2 rounded-lg shadow-lg">
                      <p className="text-sm text-white">
                        Om een API key aan te maken, volg de richtlijnen op de
                        TransIP pagina.
                      </p>
                      <p className="mt-1 text-sm">
                        <a
                          href={transipKnowledgeBase}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-400 hover:text-blue-300 underline"
                        >
                          Bekijk de richtlijnen
                        </a>
                      </p>
                      <p className="mt-1 text-sm text-white">
                        Indien u geen TransIP API Key meer wilt gebruiken,
                        schakel dan terug naar Handmatig, uw eventuele
                        ingevoerde APIKey wordt verwijderd uit de beveiligde
                        opslag.
                      </p>
                    </div>
                    <div className="absolute left-1/2 -bottom-1 -translate-x-1/2 w-2 h-2 bg-gray-900 rotate-45"></div>
                  </div>
                </div>
              </div>
              <input
                type="password"
                id="transipApiKey"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                {...register("transipApiKey", {
                  required: showApiKey,
                  validate: (value) => {
                    if (showApiKey && !value) {
                      return "API key is verplicht wanneer automatische registratie is geselecteerd";
                    }
                    return true;
                  },
                })}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DomainRegistrationOptionForm;
