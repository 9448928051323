import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { user, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  const [idToken, setIdToken] = useState(null);

  useEffect(() => {
    const getIdToken = async () => {
      if (isAuthenticated) {
        try {
          const claims = await getIdTokenClaims();
          console.log("claims");
          console.log(claims);
          setIdToken(claims.__raw);
        } catch (error) {
          console.error(error);
        }
      }
    };
    getIdToken();
  }, []);

  return (
    isAuthenticated && (
      <div className="flex flex-col mx-auto max-w-screen-xl">
        <section>
          <img src={user.picture} alt={user.name} />
          <h2>{user.name}</h2>
          <p>{user.email}</p>
          {/* <pre>{JSON.stringify(idToken, null, 2)}</pre> */}
        </section>
      </div>
    )
  );
};

export default Profile;
