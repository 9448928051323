import React from "react";
import { Info, Code, Zap } from "lucide-react";

const ToolCallMessage = ({ tool_calls }) => {
  // Determine the icon and color based on the tool type
  const getToolIcon = (toolName) => {
    const toolIcons = {
      set_topic: Zap,
      default: Info,
    };
    return toolIcons[toolName] || toolIcons["default"];
  };

  const getColorScheme = (toolName) => {
    const colorSchemes = {
      set_topic: {
        border: "border-purple-500",
        text: "text-purple-700",
        bg: "bg-purple-50",
      },
      default: {
        border: "border-blue-500",
        text: "text-blue-700",
        bg: "bg-blue-50",
      },
    };
    return colorSchemes[toolName] || colorSchemes["default"];
  };

  // Safely parse arguments
  const parseArguments = (args) => {
    try {
      return JSON.parse(args);
    } catch {
      return args;
    }
  };

  // Render each tool call
  const renderToolCall = (call, index) => {
    const { name, arguments: args } = call.function;
    const ToolIcon = getToolIcon(name);
    const { border, text, bg } = getColorScheme(name);
    const parsedArgs = parseArguments(args);

    return (
      <div
        key={index}
        className={`
          flex items-start p-3 mb-2 rounded-lg 
          ${border} ${text} ${bg} 
          border-l-4 shadow-sm
        `}
      >
        <ToolIcon className="mr-3 mt-1 flex-shrink-0" size={20} />
        <div className="flex-grow">
          <div className="font-semibold mb-1">{name}(</div>
          <pre className="text-xs overflow-x-auto pl-4">
            {JSON.stringify(parsedArgs, null, 2)}
          </pre>
          <div className="font-semibold mb-1">)</div>
        </div>
      </div>
    );
  };

  return <div className="space-y-2">{tool_calls?.map(renderToolCall)}</div>;
};

export default ToolCallMessage;
