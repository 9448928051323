import React from "react";
import { Link } from "react-router-dom";
import { COMPANY_NAME } from "./constants";
import CheckboxIcon from "./icons/CheckboxIcon";
import siteLogo from "./assets/logo.png";

export default function TrialMenu() {
  return (
    <div className="hidden w-full max-w-md p-12 lg:h-screen lg:block bg-primary-700">
      <div className="flex items-center mb-8 space-x-4">
        <Link
          to="/"
          className="flex items-center text-2xl font-semibold text-white"
        >
          <img className="w-8 h-8 mr-2" src={siteLogo} />
          {COMPANY_NAME}
        </Link>
        <Link
          to="/prijzen"
          className="inline-flex items-center text-sm font-medium text-primary-100 hover:text-white"
        >
          <svg
            className="w-6 h-6 mr-1"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          Terug
        </Link>
      </div>
      <div className="block p-8 text-white rounded-lg bg-primary-600">
        <h3 className="mb-1 text-2xl font-semibold">Uw pakket</h3>
        <p className="mb-4 font-light text-primary-100 sm:text-lg">
          14-dagen proefperiode
        </p>
        <ul role="list" className="space-y-4 text-left">
          <li className="flex items-center space-x-3">
            <CheckboxIcon></CheckboxIcon>
            <span>Individual configuration</span>
          </li>
          <li className="flex items-center space-x-3">
            <CheckboxIcon></CheckboxIcon>
            <span>No setup, or hidden fees</span>
          </li>
          <li className="flex items-center space-x-3">
            <CheckboxIcon></CheckboxIcon>
            <span>
              Team size: <span className="font-semibold">1 developer</span>
            </span>
          </li>
          <li className="flex items-center space-x-3">
            <CheckboxIcon></CheckboxIcon>
            <span>
              Premium support: <span className="font-semibold">6 months</span>
            </span>
          </li>
          <li className="flex items-center space-x-3">
            <CheckboxIcon></CheckboxIcon>
            <span>
              Free updates: <span className="font-semibold">6 months</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}
