import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useUserStore from "./hooks/user-store";
import { useApiService } from "./hooks/apiService";
import { useShallow } from "zustand/react/shallow";
import { ToggleSwitch } from "flowbite-react";
import ToolCallMessage from "./ToolCallMessage";

const AssistantMessage = ({ content, tool_calls }) => {
  const { chatShowFunctionCallMessages } = useUserStore(
    useShallow((state) => ({
      chatShowFunctionCallMessages: state.settings.chatShowFunctionCallMessages,
    })),
  );

  const name = tool_calls === undefined ? "Assistant" : "Tool";

  if (!chatShowFunctionCallMessages && tool_calls !== undefined) {
    return null;
  }

  if (name === "Tool") {
    return (
      <li className="text-red-500 text-left border-r-4 border-red-500 pr-2 rounded-lg">
        <span className="font-bold">Tool call:</span>{" "}
        {JSON.stringify(tool_calls)}
      </li>
    );
  }

  return (
    <li className="text-blue-500 text-left border-l-4 border-blue-500 pl-2 rounded-lg">
      <span className="font-bold">Assistant:</span> {content}
    </li>
  );
};

const UserMessage = ({ content }) => {
  return (
    <li className="text-green-500 text-right border-r-4 border-green-500 pr-2 rounded-lg">
      <span className="font-bold">User:</span> {content}
    </li>
  );
};

const FunctionCall = ({ name, content }) => {
  const { chatShowFunctionCallMessages } = useUserStore(
    useShallow((state) => ({
      chatShowFunctionCallMessages: state.settings.chatShowFunctionCallMessages,
    })),
  );

  if (!chatShowFunctionCallMessages) {
    return null;
  }

  return (
    <li className="text-red-500 text-left border-r-4 border-red-500 pr-2 rounded-lg">
      <span className="font-bold">GPT function call:</span> {name}({content})
    </li>
  );
};

const ToolCall = ({ name, content }) => {
  const { chatShowFunctionCallMessages } = useUserStore(
    useShallow((state) => ({
      chatShowFunctionCallMessages: state.settings.chatShowFunctionCallMessages,
    })),
  );

  if (!chatShowFunctionCallMessages) {
    return null;
  }

  return (
    <li className="text-red-500 text-left border-l-4 border-red-500 px-2 rounded-lg">
      <span className="font-bold">Tool call response:</span> {name}({content})
    </li>
  );
};

const Chat = () => {
  const { conversationId } = useParams();
  const navigate = useNavigate();
  // const chatbotUuid = useUserStore((state) => state?.userData?.chatbot?.uuid);
  const { chatbotUuid, updateSetting, chatShowFunctionCallMessages } =
    useUserStore(
      useShallow((state) => ({
        chatbotUuid: state?.userData?.chatbot?.uuid,
        updateSetting: state.updateSetting,
        chatShowFunctionCallMessages:
          state.settings.chatShowFunctionCallMessages,
      })),
    );
  const { apiService } = useApiService();
  const [messages, setMessages] = useState([]);

  console.log(`chatbotUuid: ${chatbotUuid} from Chat`);

  useEffect(() => {
    let intervalId;
    if (chatbotUuid && conversationId) {
      const getConversation = async () => {
        const conversationResponse = await apiService.getChatbotConversation(
          chatbotUuid,
          conversationId,
        );
        console.log(conversationResponse);
        setMessages(conversationResponse.messages);
      };
      getConversation();
      intervalId = setInterval(getConversation, 5000); // Refresh every 5 seconds
    }
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [chatbotUuid, conversationId]);

  const renderMessage = (msg, index) => {
    const msgData = JSON.parse(msg.message);
    const { role, content, tool_calls } = msgData;

    switch (role) {
      case "assistant":
        if (tool_calls !== undefined) {
          return (
            <ToolCallMessage key={msg.message_id} tool_calls={tool_calls} />
          );
        } else {
          return (
            <AssistantMessage
              key={msg.message_id}
              content={content}
              tool_calls={tool_calls}
            />
          );
        }
      case "user":
        return <UserMessage key={msg.message_id} content={content} />;
      // case "system":
      case "tool":
        const { toolName } = msgData;
        return (
          <ToolCall key={msg.message_id} name={toolName} content={content} />
        );
      case "function":
        const { name } = msgData;
        return (
          <FunctionCall key={msg.message_id} name={name} content={content} />
        );
      // Add more cases for additional roles
      // case 'system':
      //   return <SystemMessage key={msg.message_id} content={content} />;
      default:
        console.log(`Unsupported role: ${role}`);
        return null; // Skip rendering for unsupported roles
    }
  };

  return (
    <div className="mb-4 bg-white rounded shadow p-6">
      <div className="flex flex-wrap gap-2">
        <button
          className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          onClick={() => {
            navigate(-1);
          }}
        >
          Terug naar Chats
        </button>
        <ToggleSwitch
          label="Show assistant and tool messages"
          checked={chatShowFunctionCallMessages}
          onChange={() => {
            updateSetting(
              "chatShowFunctionCallMessages",
              !chatShowFunctionCallMessages,
            );
          }}
        />
      </div>
      <h1
        className="text-2xl font-bold text-gray-800 dark:text-gray-100 my-4"
        title={`${conversationId} ${chatbotUuid}`}
      >
        Chat {conversationId}
      </h1>
      {/* <p>Chat ID: {conversationId}</p> */}
      {/* <p>Chatbot UUID: {chatbotUuid}</p> */}
      <ul className="*:my-2 *:py-1">
        {messages && messages.map(renderMessage)}
      </ul>
    </div>
  );
};

export default Chat;
