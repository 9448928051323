import React, { useState, useEffect } from "react";
import { useApiService } from "./hooks/apiService";
import useUserStore from "./hooks/user-store";

const MollieProfileSelector = () => {
  const { apiService } = useApiService(); // Hook to use your API service
  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState("");
  const organizationId = useUserStore(
    (state) => state?.userData?.chatbot?.organization_id,
  );

  useEffect(() => {
    // Fetch Mollie profiles on component mount
    const fetchProfiles = async () => {
      try {
        const organizationResponse =
          await apiService.getOrganization(organizationId);
        setSelectedProfile(organizationResponse.organization.mollie_profile_id);
      } catch (error) {
        console.error("Error fetching organization:", error);
      }
      try {
        const response = await apiService.getMollieProfiles();
        setProfiles(response._embedded.profiles || []);
      } catch (error) {
        console.error("Error fetching Mollie profiles:", error);
      }
    };

    fetchProfiles();
  }, []);

  const handleSelectionChange = async (event) => {
    const mollieProfileId = event.target.value;
    setSelectedProfile(mollieProfileId);

    try {
      await apiService.updateOrganization(organizationId, mollieProfileId);
      console.log(`Profile ${mollieProfileId} successfully updated.`);
    } catch (error) {
      console.error("Error updating organization with profile:", error);
    }
  };

  return (
    <div className="w-full max-w-md mb-4 mt-8">
      <label
        htmlFor="mollie-profile"
        className="block text-sm font-medium text-gray-700 mb-2"
      >
        Selecteer Mollie Profile (dit is het profiel dat wordt gebruikt wanneer
        uw klanten willen betalen)
      </label>
      <select
        id="mollie-profile"
        value={selectedProfile}
        onChange={handleSelectionChange}
        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
      >
        <option value="" disabled>
          -- Selecteer een profiel --
        </option>
        {profiles.map((profile) => (
          <option key={profile.id} value={profile.id}>
            {profile.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MollieProfileSelector;
