import React, { useEffect, useState } from "react";
import { CheckCircle, HeartCrack } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { useLocation, useParams } from "react-router-dom";
import { useApiService } from "./hooks/apiService";
import Timeline from "./Timeline";

const BREAK = "%0D%0A";
const OrderStatus = () => {
  const { workOrderId } = useParams();
  const { hash } = useLocation();
  const { apiService } = useApiService();

  const cancelled = hash === "#cancelled";
  const [temporaryUrl, setTemporaryUrl] = useState("https://example.com");
  const [newUrl, setNewUrl] = useState("https://example.com");
  const [supportEmail, setSupportEmail] = useState("support@sitechatpro.nl");
  const [tasks, setTasks] = useState([]);
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleOrderStatus = async () => {
      const orderStatusData = await apiService.getOrderStatus(workOrderId);
      const orderStatus = orderStatusData.order_status;
      console.log(`orderStatus: ${JSON.stringify(orderStatus)}`);
      setTemporaryUrl(orderStatus.temporary_domain);
      setNewUrl(orderStatus.target_domain);
      setSupportEmail(orderStatus.support_email);
      setTasks(orderStatus.tasks);
    };
    if (!cancelled) {
      handleOrderStatus();
    }
  }, [workOrderId, hash, time]);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <Card className="w-full max-w-lg">
        <CardContent className="pt-6">
          <div className="text-center">
            {cancelled ? (
              <HeartCrack className="mx-auto h-12 w-12 text-red-500" />
            ) : (
              <CheckCircle className="mx-auto h-12 w-12 text-green-500" />
            )}

            {cancelled ? (
              <h1 className="mt-4 text-2xl font-bold text-gray-900">
                Betaling geannuleerd... 😢
              </h1>
            ) : (
              <h1 className="mt-4 text-2xl font-bold text-gray-900">
                Betaling succesvol! 🎉🥳🎉
              </h1>
            )}

            {!cancelled && (
              <>
                <p className="mt-2 text-gray-600">
                  Gefeliciteerd met de aankoop van je website!
                </p>
                <p className="mt-2 text-gray-600">
                  Je website is tijdelijk nog beschikbaar op{" "}
                  <a
                    href={temporaryUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="font-semibold text-blue-600 hover:underline cursor-pointer"
                  >
                    {temporaryUrl}
                  </a>{" "}
                  maar wordt zo spoedig mogelijk overgezet naar{" "}
                  <a
                    href={newUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="font-semibold text-blue-600 hover:underline cursor-pointer"
                  >
                    {newUrl}
                  </a>
                  .
                </p>
              </>
            )}

            {/* Timeline */}
            {!cancelled && <Timeline tasks={tasks} />}

            <div className="mt-8 space-y-4">
              {!cancelled && (
                <div className="bg-gray-100 p-4 rounded-lg">
                  <h2 className="font-semibold text-gray-900">
                    Volgende stappen:
                  </h2>
                  <ul className="mt-2 space-y-2 text-gray-600 text-left">
                    <li>
                      • U ontvangt een e-mail met daarin uw website
                      inloggegevens
                    </li>
                    <li>
                      • Uw website wordt overgezet naar het door uw gekozen
                      domeinnaam {newUrl}
                    </li>
                    <li>
                      • Ondertussen kunt u gewoon uw website blijven beheren op{" "}
                      <a
                        href={temporaryUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="font-semibold text-blue-600 hover:underline cursor-pointer"
                      >
                        {temporaryUrl}
                      </a>
                      , zodra de migratie start wordt uw website in maintenance
                      mode gezet
                    </li>
                    <li>
                      • Zodra uw website is overgezet ontvang u nog een
                      bevestigings e-mail
                    </li>
                  </ul>
                </div>
              )}

              {cancelled && (
                <div className="bg-gray-100 p-4 rounded-lg flex flex-col gap-3">
                  <p className="text-gray-600">
                    Wilt u toch nog de door u gegenereerde website kopen? Klik
                    dan op de onderstaande knop om het betaalproces opnieuw te
                    starten.
                  </p>
                  <a
                    href="/order"
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                  >
                    Bestel uw website 🚀
                  </a>
                </div>
              )}

              {/* <div className="flex flex-col gap-3"> */}
              {/*   <button className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"> */}
              {/*     Go to Dashboard */}
              {/*   </button> */}
              {/*   <button className="text-blue-600 hover:text-blue-700"> */}
              {/*     View Documentation */}
              {/*   </button> */}
              {/* </div> */}
            </div>

            <p className="mt-6 text-sm text-gray-500">
              Hulp nodig of gaat er iets mis?{" "}
              <a
                href={`mailto:${supportEmail}?subject=Vraag/Hulp na betaling ${workOrderId} ${newUrl}&body=betreft ${temporaryUrl} ${newUrl}${BREAK}${BREAK}type hier uw bericht`}
              >
                Neem contact op met support
              </a>
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default OrderStatus;
