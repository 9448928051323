import React from "react";
import useUserStore from "./hooks/user-store";
import QuestionIcon from "./icons/QuestionIcon";
import ClipboardIcon from "./icons/ClipboardIcon";
import { toast } from "react-toastify";

const Documentation = () => {
  const chatbotUuid = useUserStore((state) => state?.userData?.chatbot?.uuid);
  const code = `<div id="sitechatpro"></div>
<script crossorigin type="module" src="https://cdn.sitechatpro.nl/latest/sitechatpro.js"></script>
<script>
    window.onload = function() {
        sitechatpro({containerId: "sitechatpro", chatbotId: "${chatbotUuid}", floatingWidget: true});
    };
</script>`;
  const copyToClipboard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(code);
    toast("Code copied to clipboard");
  };

  const docs = [
    {
      title: "Hoe integreer ik de chatbot in mijn website?",
      content: (
        <>
          <p className="mt-2 text-gray-600 dark:text-gray-400">
            De chatbot kan eenvoudig worden geïntegreerd in uw website door de
            chatbot code toe te voegen aan de body-tag van uw website:
          </p>
          <div className="relative">
            <pre className="overflow-auto mt-4 p-4 bg-gray-100 dark:bg-gray-800 rounded text-sm text-gray-600 dark:text-gray-400">
              <code id="embedBotCode">{code}</code>
            </pre>
            <button
              onClick={copyToClipboard}
              className="absolute top-2 right-2 p-1 bg-gray-200 dark:bg-gray-700 rounded hover:bg-gray-300 dark:hover:bg-gray-600"
            >
              <ClipboardIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />
            </button>
          </div>
        </>
      ),
    },
    {
      title: "Hoe kan ik de chatbot op specifieke plek op mijn website tonen?",
      content: (
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          De tag <code>{`<div id="sitechatpro"></div>`}</code> is de plek waar
          de chatbot zal worden weergegeven. U kunt deze div verplaatsen naar de
          gewenste locatie op uw website. Het maakt niet uit of deze div zich
          voor of na de script tags bevindt.
        </p>
      ),
    },
    {
      title:
        "Kan ik help krijgen bij het plaatsen van de chatbot code op mijn website?",
      content: (
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          Ja, wij helpen u graag met het plaatsen van de chatbot code op uw
          website. Neem contact met ons door een e-mail naar{" "}
          <a
            className="text-blue-600 hover:underline"
            target="_blank"
            rel="noreferrer"
            href="mailto:support@sitechatpro.nl"
          >
            support@sitechatpro.nl
          </a>{" "}
          te sturen.
        </p>
      ),
    },
  ];

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="bg-white rounded shadow p-6">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          Technische Documentatie
        </h2>
        <div className="grid pt-8 text-left border-t border-gray-200 md:gap-4 dark:border-gray-700 md:grid-cols-2 grid-cols-1">
          {docs.map((docs, i) => (
            <div key={i} className="mb-10">
              <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                <QuestionIcon />
                {docs.title}
              </h3>
              {docs.content}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Documentation;
