import { ChevronLeft, ChevronRight } from "lucide-react";
import React from "react";

const Pagination = ({ currentPage, pageSize, totalItems, onPageChange }) => {
  // console.log(currentPage, pageSize, totalItems);
  const totalPages = Math.ceil(totalItems / pageSize);
  // console.log(totalPages);
  const pages = [...Array(totalPages).keys()].map((page) => page + 1);

  const firstPageItem = currentPage * pageSize - pageSize + 1;
  const lastPageItem = Math.min(currentPage * pageSize, totalItems);
  return (
    <nav
      className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
      aria-label="Table navigation"
    >
      <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
        Showing{" "}
        <span className="font-semibold text-gray-900 dark:text-white">
          {firstPageItem}-{lastPageItem}
        </span>{" "}
        of{" "}
        <span className="font-semibold text-gray-900 dark:text-white">
          {totalItems}
        </span>
      </span>
      <ul className="inline-flex items-stretch -space-x-px">
        <li>
          <a
            href="#"
            className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={() => {
              if (currentPage > 1) {
                onPageChange(currentPage - 1);
              }
            }}
          >
            <span className="sr-only">Previous</span>
            <ChevronLeft className="w-5 h-5" />
          </a>
        </li>
        {pages.map((page) => (
          <li key={page}>
            <a
              href="#"
              onClick={() => onPageChange(page)}
              aria-current={page === currentPage ? "page" : null}
              className={
                page !== currentPage
                  ? "flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  : "z-10 flex items-center justify-center px-3 py-2 text-sm leading-tight border text-primary-600 bg-primary-50 border-primary-300 hover:bg-primary-100 hover:text-primary-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              }
            >
              {page}
            </a>
          </li>
        ))}
        <li>
          <a
            href="#"
            className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={() => {
              if (currentPage < totalPages) {
                onPageChange(currentPage + 1);
              }
            }}
          >
            <span className="sr-only">Next</span>
            <ChevronRight className="w-5 h-5" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
