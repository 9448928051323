import React, { useEffect, useState } from "react";
import { printDateInUserLocale } from "./utils/date";
import { useApiService } from "./hooks/apiService";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";
import useUserStore from "./hooks/user-store";
import EyeIcon from "./icons/EyeIcon";

const Orders = () => {
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const { apiService } = useApiService();
  const chatbotUUID = useUserStore((state) => state?.userData?.chatbot?.uuid);

  console.log(currentPage, totalItems);

  async function getTableData() {
    if (chatbotUUID !== "") {
      let data = await apiService.getOrders(chatbotUUID, currentPage);
      console.log("tabledata", data);
      setTableData(data.orders);
      setTotalItems(data.total_items);
    } else {
      console.log("chatbotUUID is empty");
    }
  }

  useEffect(() => {
    getTableData();
  }, [chatbotUUID, currentPage]);

  return (
    <div className="mb-4 bg-white rounded shadow p-6">
      <h2 className="mb-3 text-xl font-bold text-gray-900 dark:text-white">
        Orders
      </h2>
      <section className="bg-white antialiased dark:bg-gray-900">
        <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {/* <th scope="col" className="p-4"> */}
                  {/*   <div className="flex items-center"> */}
                  {/*     <input */}
                  {/*       id="checkbox-all" */}
                  {/*       type="checkbox" */}
                  {/*       className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" */}
                  {/*     ></input> */}
                  {/*     <label htmlFor="checkbox-all" className="sr-only"> */}
                  {/*       checkbox */}
                  {/*     </label> */}
                  {/*   </div> */}
                  {/* </th> */}
                  <th scope="col" className="px-4 py-3">
                    domain name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Order Status
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Order Bedrag
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Betaal Status
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Generated Sites
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Gemaakt op
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Laatste update
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row) => (
                  <tr
                    key={row.id}
                    className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    {/* <td className="w-4 px-4 py-3"> */}
                    {/*   <div className="flex items-center"> */}
                    {/*     <input */}
                    {/*       id="checkbox-table-search-1" */}
                    {/*       type="checkbox" */}
                    {/*       onClick={(event) => event.stopPropagation()} */}
                    {/*       className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" */}
                    {/*     ></input> */}
                    {/*     <label */}
                    {/*       htmlFor="checkbox-table-search-1" */}
                    {/*       className="sr-only" */}
                    {/*     > */}
                    {/*       checkbox */}
                    {/*     </label> */}
                    {/*   </div> */}
                    {/* </td> */}
                    <th
                      scope="row"
                      className="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <Link
                        to={`/dashboard/orders/${row.id}`}
                        className="hover:underline"
                      >
                        <span className="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-primary-900 dark:text-primary-300">
                          {row.site_config.target_domain}
                        </span>
                        <EyeIcon />
                      </Link>
                      {/* put a eye view icon here */}
                    </th>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {row.status}
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      € {row.payment?.amount?.toFixed(2) || "-"}
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {row.payment?.status || "no payment associated"}
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-normal dark:text-white flex flex-col">
                      <a
                        href={row.site_config.site_url}
                        target="_blank"
                        className="hover:underline"
                      >
                        {row.site_config.site_url}
                      </a>
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {printDateInUserLocale(row.created_at)}
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {printDateInUserLocale(row.updated_at)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={currentPage}
            pageSize={25}
            totalItems={totalItems}
            onPageChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </section>
    </div>
  );
};

export default Orders;
