export const COMPANY_NAME = import.meta.env.VITE_COMPANY_NAME || "Company Name";
export const RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
export const COMPANY_EMAIL =
  import.meta.env.VITE_COMPANY_EMAIL || "info@hensenitsolutions.nl";
export const SITE_URL = import.meta.env.BASE_URL;
export const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID;
export const AUTH0_CALLBACK_URL = import.meta.env.VITE_AUTH0_CALLBACK_URL;
export const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;
export const AUTH0_SCOPE = import.meta.env.VITE_AUTH0_SCOPE;
export const MOLLIE_AUTH_ENDPOINT = import.meta.env.VITE_MOLLIE_AUTH_ENDPOINT;
export const MOLLIE_CLIENT_ID = import.meta.env.VITE_MOLLIE_CLIENT_ID;
export const MOLLIE_REDIRECT_URI = import.meta.env.VITE_MOLLIE_REDIRECT_URI;
