import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import useUserStore from "./hooks/user-store";
import { useApiService } from "./hooks/apiService";

const Logout = () => {
  const { logout, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const { apiService } = useApiService();
  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      apiService.clearToken();
      useUserStore.persist.clearStorage();
      handleLogout();
    } else {
      navigate("/");
    }
  }, []);

  // TODO make this a nice goodbye page
  return <div>logout</div>;
};

export default Logout;
