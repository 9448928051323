import React, { useState } from "react";
import { Eye, EyeOff, Copy } from "lucide-react";
import { toast } from "react-toastify";

const SecretValue = ({ secret }) => {
  const [isRevealed, setIsRevealed] = useState(false);

  const toggleReveal = () => {
    setIsRevealed(!isRevealed);
  };

  const copyToClipboard = () => {
    if (secret) {
      navigator.clipboard.writeText(secret).then(() => {
        toast("Code copied to clipboard");
      });
    }
  };

  // If secret is null, show a placeholder or message
  if (secret === null) {
    return (
      <div className="m-1 flex items-center gap-2 py-0 px-2 border rounded-lg shadow-sm">
        <span className="flex-1 font-mono text-gray-400 overflow-x-auto text-nowrap">
          No secret available
        </span>
        <button disabled className="p-2 text-gray-300 cursor-not-allowed">
          <Eye size={16} />
        </button>
        <button disabled className="p-2 text-gray-300 cursor-not-allowed">
          <Copy size={16} />
        </button>
      </div>
    );
  }

  return (
    <div className="m-1 flex items-center gap-2 py-0 px-2 border rounded-lg shadow-sm">
      <span className="flex-1 font-mono">
        {isRevealed ? secret : "*".repeat(Math.min(20, secret.length))}
      </span>
      <button
        onClick={toggleReveal}
        className="p-2 text-gray-500 hover:text-gray-700"
      >
        {isRevealed ? <EyeOff size={16} /> : <Eye size={16} />}
      </button>
      <button
        onClick={copyToClipboard}
        className="p-2 text-gray-500 hover:text-gray-700"
      >
        <Copy size={16} />
      </button>
    </div>
  );
};

export default SecretValue;
