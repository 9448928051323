import React, { useState, useEffect } from "react";
import { AlertCircle, X } from "lucide-react";
import useUserStore from "./hooks/user-store";
import { Link } from "react-router-dom";

const IncompleteSettingsAlerts = () => {
  const chatbotState = useUserStore((state) => state?.userData?.chatbot);
  const [dismissedAlerts, setDismissedAlerts] = useState({
    openai: false,
    mollie: false,
    transip: false,
  });

  useEffect(() => {
    if (chatbotState?.id) {
      // Load dismissed state from localStorage on component mount
      const savedOpenAI = localStorage.getItem(
        `dismissed_openai_alert_${chatbotState.uuid}`,
      );
      const savedMollie = localStorage.getItem(
        `dismissed_mollie_alert_${chatbotState.uuid}`,
      );
      const savedTransip = localStorage.getItem(
        `dismissed_transip_alert_${chatbotState.uuid}`,
      );

      setDismissedAlerts({
        openai: savedOpenAI === "true",
        mollie: savedMollie === "true",
        transip: savedTransip === "true",
      });
    }
  }, [chatbotState?.id]);

  if (!chatbotState) {
    return null;
  }

  // const showOpenAIWarning = true;
  // const showTransipWarning = true;
  // const showMollieWarning = true;
  const showOpenAIWarning =
    !chatbotState.openai_api_key_set && !dismissedAlerts.openai;
  const showTransipWarning =
    !chatbotState.transip_api_key_set && !dismissedAlerts.transip;
  const showMollieWarning =
    !chatbotState.organization?.is_connected && !dismissedAlerts.mollie;

  // If no warnings to show, don't render anything
  if (!showOpenAIWarning && !showTransipWarning && !showMollieWarning) {
    return null;
  }

  const handleDismiss = (alertType) => {
    // Show confirmation dialog
    if (
      window.confirm(
        "Als je deze melding verbergt, zie je deze niet meer totdat je de verborgen meldingen wist in de instellingen. Weet je het zeker?",
      )
    ) {
      // Update local state
      setDismissedAlerts((prev) => ({
        ...prev,
        [alertType]: true,
      }));

      // Save to localStorage
      localStorage.setItem(
        `dismissed_${alertType}_alert_${chatbotState.id}`,
        "true",
      );
    }
  };

  // openai api key warning is not dismissable
  return (
    <div className="space-y-4 mb-6">
      {showOpenAIWarning && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-md relative">
          {/* <button */}
          {/*   onClick={() => handleDismiss("openai")} */}
          {/*   className="absolute top-2 right-2 text-red-500 hover:text-red-700" */}
          {/*   aria-label="Melding verbergen" */}
          {/* > */}
          {/*   <X className="h-5 w-5" /> */}
          {/* </button> */}
          <div className="flex items-start pr-6">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-red-500" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                OpenAI API Sleutel Ontbreekt
              </h3>
              <div className="mt-1 text-sm text-red-700">
                Je chatbot kan niet functioneren zonder een OpenAI API sleutel.
                Voeg je API sleutel toe in de{" "}
                <Link
                  to="/dashboard/settings"
                  className="text-blue-600 hover:underline"
                >
                  instellingen
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
      )}

      {showMollieWarning && (
        <div className="bg-amber-50 border-l-4 border-amber-500 p-4 rounded-md relative">
          <button
            onClick={() => handleDismiss("mollie")}
            className="absolute top-2 right-2 text-amber-500 hover:text-amber-700"
            aria-label="Melding verbergen"
          >
            <X className="h-5 w-5" />
          </button>
          <div className="flex items-start pr-6">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-amber-500" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-amber-800">
                Mollie Connect Niet Ingeschakeld
              </h3>
              <div className="mt-1 text-sm text-amber-700">
                Betalingsdiensten kunnen niet worden aangeboden totdat je je
                Mollie-account koppelt.{" "}
                <Link
                  to="/dashboard/mollie_redirect"
                  className="text-blue-600 hover:underline"
                >
                  Schakel Mollie Connect in om betalingen van uw klanten te
                  kunnen ontvangen.
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {showTransipWarning && (
        <div className="bg-amber-50 border-l-4 border-amber-500 p-4 rounded-md relative">
          <button
            onClick={() => handleDismiss("transip")}
            className="absolute top-2 right-2 text-amber-500 hover:text-amber-700"
            aria-label="Melding verbergen"
          >
            <X className="h-5 w-5" />
          </button>
          <div className="flex items-start pr-6">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-amber-500" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-amber-800">
                TransIP API Sleutel Ontbreekt
              </h3>
              <div className="mt-1 text-sm text-amber-700">
                Domeinregistratie is niet beschikbaar. Voeg je TransIP API
                sleutel toe in de{" "}
                <Link
                  to="/dashboard/settings"
                  className="text-blue-600 hover:underline"
                >
                  instellingen
                </Link>{" "}
                om automatische domeinregistratie mogelijk te maken.
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IncompleteSettingsAlerts;
