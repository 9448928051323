import React from "react";
import { useState } from "react";
import FileUploader from "./FileUploader";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import withRequiresAuth from "./hoc/requires-auth";
import { Link, useNavigate } from "react-router-dom";
import ReadonlyCopyInput from "./components/CopyInput";
import { useApiService } from "./hooks/apiService";
import UndoIcon from "./icons/UndoIcon";
import DomainRegistrationOptionForm from "./components/DomainRegistrationOptionForm";
import useUserStore from "./hooks/user-store";

function Settings() {
  const navigate = useNavigate();
  const [chatbotUUID, setChatbotUUID] = useState("");
  const { apiService } = useApiService();
  const updateChatbot = useUserStore((state) => state.updateChatbot);

  async function getChatbotSettings() {
    let response = await apiService.getMyChatbotSettings();
    console.log("chatbot settings:", response);
    if (response.length === 0) {
      navigate("/trial");
    } else {
      const firstChatbot = response[0];
      setChatbotUUID(firstChatbot.uuid);
      const chatbotData = {
        chatbotName: firstChatbot.name,
        chatbotLogoUrl: firstChatbot.logo_url,
        description: firstChatbot.description,
        welcomeMessage: firstChatbot.welcome_message,
        domainRegistrationType: firstChatbot.domain_registration_type,
        transipApiKeySet: firstChatbot.transip_api_key_set,
        openaiApiKeySet: firstChatbot.openai_api_key_set,
      };
      console.log(chatbotData);
      return chatbotData;
    }
  }

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: async () => getChatbotSettings(),
  });

  const transipApiKeySet = watch("transipApiKeySet");
  const openaiApiKeySet = watch("openaiApiKeySet");
  const domainRegistrationType = watch("domainRegistrationType");

  const onSubmit = async (data) => {
    const chatbotData = {
      name: data.chatbotName,
      logo_url: data.chatbotLogoUrl,
      description: data.description,
      welcome_message: data.welcomeMessage,
      domain_registration_type: data.domainRegistrationType,
    };
    console.log(`data:`, data);
    console.log(`dirtyFields:`, dirtyFields);

    if (!dirtyFields.transipApiKey) {
      delete data.transipApiKey;
    }
    if (!dirtyFields.openaiApiKey) {
      console.log("gets deleted");
      delete data.openaiApiKey;
    }

    if ("transipApiKey" in data) {
      chatbotData.transip_api_key = data.transipApiKey;
    }
    if ("openaiApiKey" in data) {
      console.log(`openaiApiKey:`, data.openaiApiKey);
      console.log("adding openaiApiKey to chatbotData");
      chatbotData.openai_api_key = data.openaiApiKey;
    }
    console.log(`chatbotData:`, chatbotData);

    const chatbotRes = await toast.promise(
      apiService.updateChatbotSettings(chatbotUUID, chatbotData),
      {
        pending: "Updating chatbot settings... 🤖",
        success: "Chatbot settings updated successfully 🎉",
        error: "Failed to update chatbot settings 😢",
      },
    );
    console.log(chatbotRes);
    updateChatbot(chatbotRes);
  };

  const handleClearOpenAIKey = () => {
    setValue("openaiApiKey", "", { shouldDirty: true });
    setValue("openaiApiKeySet", false);
  };

  const handleReset = async () => {
    const originalValues = await getChatbotSettings();
    reset(originalValues);
    toast.info("Wijzigingen ongedaan gemaakt");
  };

  const chatbotLogoUrl = watch("chatbotLogoUrl");
  const chatbotName = watch("chatbotName");
  const defaultLogoUrl = `https://images.placeholders.dev/?width=300&height=300&text=${chatbotName || "your logo"}&bgColor=%23f7f6f6&textColor=%236d6e71&textWrap=true`;

  return (
    <div>
      <div className="bg-white rounded shadow p-6">
        <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
          Chatbot Instellingen
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-4 mb-4 xl:grid-cols-2 lg:gap-8">
            {/* First Column - General Settings */}
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                  Algemene Instellingen
                </h3>
                <div className="space-y-6">
                  <div>
                    <label
                      htmlFor="chatbotUuid"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Chatbot ID (readonly)
                    </label>
                    <ReadonlyCopyInput id="chatbotUuid" value={chatbotUUID} />
                    <label
                      htmlFor="chatbotUuid"
                      className="block mt-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      <Link
                        className="text-blue-600 hover:underline"
                        to="/dashboard/documentation"
                      >
                        Zie: Hoe integreer ik de chatbot in mijn website?
                      </Link>
                    </label>
                  </div>

                  <div className="grid grid-cols-1 2xl:grid-cols-2 gap-6">
                    <div>
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Chatbot Naam
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Chatbot name"
                        required=""
                        {...register("chatbotName", { required: true })}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="brand"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Chatbot Logo
                      </label>
                      <FileUploader
                        id="chatbot-logo"
                        required={false}
                        defaultUrl={defaultLogoUrl}
                        fileUrl={chatbotLogoUrl}
                        setUrlCallback={(logoUrl) => {
                          console.log(`setting chatbotLogoUrl: ${logoUrl}`);
                          setValue("chatbotLogoUrl", logoUrl);
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Beschrijving
                    </label>
                    <textarea
                      id="description"
                      rows={5}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Write a product description here..."
                      {...register("description", { required: true })}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="welcomeMessage"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Welkomst Bericht
                    </label>
                    <textarea
                      id="welcomeMessage"
                      rows={5}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Write a welcome message here..."
                      {...register("welcomeMessage", { required: true })}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Second Column - API Settings */}
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                  API Instellingen
                </h3>
                <div className="space-y-6">
                  <div>
                    <label
                      htmlFor="openaiApiKey"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      OpenAI API Key
                    </label>
                    <div className="flex gap-2">
                      <input
                        type="password"
                        id="openaiApiKey"
                        className="flex-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder={
                          openaiApiKeySet
                            ? "••••••••"
                            : "Enter your OpenAI API key"
                        }
                        {...register("openaiApiKey", {
                          onChange: (e) =>
                            setValue(
                              "openaiApiKeySet",
                              e.target.value.length > 0,
                            ),
                        })}
                      />
                      {openaiApiKeySet && (
                        <button
                          type="button"
                          onClick={handleClearOpenAIKey}
                          className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200"
                        >
                          API Key verwijderen
                        </button>
                      )}
                    </div>
                    {openaiApiKeySet && (
                      <p className="mt-2 text-sm text-gray-500">
                        An API key is currently set
                      </p>
                    )}
                  </div>

                  <DomainRegistrationOptionForm
                    register={register}
                    setValue={setValue}
                    transipApiKeySet={transipApiKeySet}
                    domainRegistrationType={domainRegistrationType}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end space-x-4 mt-6">
            <button
              type="button"
              onClick={handleReset}
              className="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
            >
              <UndoIcon />
              Ongedaan maken
            </button>
            <button
              type="submit"
              className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Wijzigingen opslaan
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default withRequiresAuth(Settings);
