import React from "react";

const ObscuredEmail = ({ className, email }) => {
  if (!email) return null;
  const [username, domain] = email.split("@");

  const mask = ".";
  // replace the middle letters with mask for as much letters there are in the middle
  const obscured = `${username.slice(0, 2)}${mask.repeat(username.length - 4)}${username.slice(-2)}@${domain.slice(0, 2)}${mask.repeat(domain.length - 4)}${domain.slice(-2)}`;
  // const obscured = `${username.slice(0, 2)}...${username.slice(-2)}@${domain.slice(0, 2)}...${domain.slice(-2)}`;

  return (
    <div className={className}>
      <span className="group relative inline-block">
        <span className="invisible">{email}</span>
        <span className="absolute inset-0 group-hover:opacity-0">
          {obscured}
        </span>
        <span className="absolute inset-0 opacity-0 group-hover:opacity-100">
          {email}
        </span>
      </span>
    </div>
  );
};

export default ObscuredEmail;
