import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useUserStore = create(
  persist(
    (set) => ({
      userData: {
        fullname: null,
        companyName: null,
        email: null,
        chatbot: null,
      },
      settings: {
        chatShowFunctionCallMessages: true,
      },
      updateUserState: (newData) =>
        set((state) => ({
          userData: { ...state.userData, ...newData },
        })),
      updateSettings: (newSettings) =>
        set((state) => ({
          settings: { ...state.settings, ...newSettings },
        })),
      updateChatbot: (newChatbot) =>
        set((state) => ({
          userData: {
            ...state.userData,
            chatbot: { ...state.userData.chatbot, ...newChatbot },
          },
        })),
      updateSetting(key, value) {
        set((state) => {
          return {
            settings: {
              ...state.settings,
              [key]: value,
            },
          };
        });
      },
    }),
    { name: "user-store", storage: createJSONStorage(() => sessionStorage) },
  ),
);

export default useUserStore;
