import React from "react";
import { useEffect, useState } from "react";
import Pagination from "./Pagination";
import withRequiresAuth from "./hoc/requires-auth";
import { Link } from "react-router-dom";
import { useApiService } from "./hooks/apiService";
import { printDateInUserLocale } from "./utils/date";
import useUserStore from "./hooks/user-store";
import ChatStatus from "./components/ChatStatus";

function Chats() {
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const { apiService } = useApiService();
  const chatbotUUID = useUserStore((state) => state?.userData?.chatbot?.uuid);

  async function getTableData() {
    if (chatbotUUID !== "") {
      let data = await apiService.getChatbotConversations(
        chatbotUUID,
        currentPage,
      );
      setTableData(data.conversations);
      setTotalItems(data.total_items);
    }
  }

  useEffect(() => {
    getTableData();
  }, [chatbotUUID, currentPage]);

  return (
    <div className="mb-4 bg-white rounded shadow p-6">
      <h2 className="mb-3 text-xl font-bold text-gray-900 dark:text-white">
        Chats
      </h2>
      <section className="bg-white antialiased dark:bg-gray-900">
        <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {/* <th scope="col" className="p-4"> */}
                  {/*   <div className="flex items-center"> */}
                  {/*     <input */}
                  {/*       id="checkbox-all" */}
                  {/*       type="checkbox" */}
                  {/*       className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" */}
                  {/*     ></input> */}
                  {/*     <label htmlFor="checkbox-all" className="sr-only"> */}
                  {/*       checkbox */}
                  {/*     </label> */}
                  {/*   </div> */}
                  {/* </th> */}
                  <th scope="col" className="px-4 py-3">
                    ID
                  </th>
                  <th scope="col" className="px-4 py-3">
                    User
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Message Count
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Gemaakt op
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Laatste update
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row) => (
                  <tr
                    key={row.conversation_id}
                    className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    {/* <td className="w-4 px-4 py-3"> */}
                    {/*   <div className="flex items-center"> */}
                    {/*     <input */}
                    {/*       id="checkbox-table-search-1" */}
                    {/*       type="checkbox" */}
                    {/*       onClick={(event) => event.stopPropagation()} */}
                    {/*       className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" */}
                    {/*     ></input> */}
                    {/*     <label */}
                    {/*       htmlFor="checkbox-table-search-1" */}
                    {/*       className="sr-only" */}
                    {/*     > */}
                    {/*       checkbox */}
                    {/*     </label> */}
                    {/*   </div> */}
                    {/* </td> */}
                    <th
                      scope="row"
                      className="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <Link
                        to={`/dashboard/chats/${row.conversation_id}`}
                        className="hover:underline"
                      >
                        {row.conversation_id}
                        <svg
                          className="w-4 h-4 text-gray-800 dark:text-white inline-block ml-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeWidth="2"
                            d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                          />
                          <path
                            stroke="currentColor"
                            strokeWidth="2"
                            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                        </svg>
                      </Link>
                      {/* put a eye view icon here */}
                    </th>
                    <td className="px-4 py-2">
                      <span className="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-primary-900 dark:text-primary-300">
                        {row.user.username}
                      </span>
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <ChatStatus status={row.status} />
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {row.message_count}
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {printDateInUserLocale(row.created_at)}
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {printDateInUserLocale(row.last_message?.timestamp) ??
                        "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={currentPage}
            pageSize={25}
            totalItems={totalItems}
            onPageChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </section>
    </div>
  );
}
export default withRequiresAuth(Chats);
