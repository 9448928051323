import { useEffect, useState } from "react";

import { createContext, useContext } from "react";
import { useLocation } from "react-router-dom";

const MenuVisibilityContext = createContext(undefined);

export const useMenuVisibility = () => {
  return useContext(MenuVisibilityContext);
};

export default function MenuProvider({ children }) {
  console.log("MenuProvider.children", children);
  const [isMenuVisible, setMenuVisible] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // main menu NOT visible on dashboard pages
    setMenuVisible(
      location.pathname.indexOf("/dashboard") !== 0 &&
        location.pathname.indexOf("/trial") !== 0,
    );
  }, [location]);

  return (
    <MenuVisibilityContext.Provider value={{ isMenuVisible, setMenuVisible }}>
      {children}
    </MenuVisibilityContext.Provider>
  );
}
