import React, { useEffect, useState } from "react";
import { useApiService } from "./hooks/apiService";

const Plan = () => {
  const { apiService } = useApiService();
  const [hasTrial, setHasTrial] = useState(false);

  useEffect(() => {
    const checkHasTrial = async () => {
      const hasTrial = await apiService.hasTrial();
      console.log(`hasTrial: ${JSON.stringify(hasTrial)}`);
      setHasTrial(hasTrial.has_trial);
    };
    checkHasTrial();
  }, [apiService]);

  // useEffect(() => {
  //   const fetchPlans = async () => {
  //     const plans = await apiService.getPlans();
  //     console.log(`plans: ${plans}`);
  //   };
  //   fetchPlans();
  // }, [apiService]);

  return (
    <div className="mb-4 bg-white rounded shadow p-6">
      {hasTrial && (
        <div className="mb-4">
          <h2 className="text-2xl font-bold mb-4">Uw Proefperiode</h2>
          <p>
            U bevindt zich momenteel in een{" "}
            <strong>14-daagse proefperiode</strong>.{" "}
            <span className="text-green-600">Nog 12 dagen</span> over.
          </p>
        </div>
      )}

      <h2 className="text-2xl font-bold mb-4">Aankomende Factuur</h2>
      <p>Uw eerste factuur wordt gegenereerd na afloop van de proefperiode.</p>
      <ul className="list-disc list-inside">
        <li>
          Abonnementsplan: <strong>Pro Plan</strong>
        </li>
        <li>
          Maandelijkse Kosten: <strong>€49,99</strong>
        </li>
        <li>
          Factuurdatum: <strong>15e van elke maand</strong>
        </li>
      </ul>
    </div>
  );
};

export default Plan;
